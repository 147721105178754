import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardService } from '@mpng/home';

import { ComprovacaoUsuarioComponent } from './comprovacao-usuario.component';
import { ComprovacaoAuxilioEducacaoAvisoComponent } from './aux-educ-aviso/comprovacao-auxilio-educacao-aviso.component';


const comprovacaoAuxEducRoutes: Routes = [


  // Todo - futuramente utilizar esse módulo para o roteamento
  {
    path: 'comprovacao-auxilio-educacao-aviso',
    component: ComprovacaoAuxilioEducacaoAvisoComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'comprovacao-auxilio-educacao',
    component: ComprovacaoUsuarioComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Comprovação Auxílio-Educação-Membro',
      icon: 'mp-icon mp-comprovacao-auxilio',
      info: '',
      /*Todo - Verificar se há necessidade de manter o role servidor*/
      roles: ['membro'],
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(comprovacaoAuxEducRoutes)],
  exports: [RouterModule]
})
export class ComprovacaoUsuarioRoutingModule {
}
