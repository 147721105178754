import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-pendencias',
  templateUrl: './info-pendencias.component.html',
  styleUrls: ['./info-pendencias.component.css']
})
export class InfoPendenciasComponent implements OnInit {

  @Input() displayPendente: boolean;

  constructor() { }

  ngOnInit() {
  }

}
