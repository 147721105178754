export class ComprovacaoBenfView {

  idComprovacaoBenef: number;
  nmFuncionario: string;
  cdMatricula: string;
  numDepen: number;
  qtdAnexo: number;
  nomeDependente: string;
  nomeBenef: string;
  tipoBeneficiario: string;
  idStatusComprovante?: number;
  pendente?: string;
}

