import { Email } from './comprovacao-outros-dados';

export class AlterarStatusComprovacaoModel {
  cdMatricula: string;
  numDepen: number;
  idComprovacao: number;
  idPeriodoReferencia: number;
  idNovoStatus: number;
  email: Email;
}
