import { Component, OnInit, ViewChild } from '@angular/core';
import { ComprovacaoAuxilioEducacaoService } from '../../shared';
import {
  ComprovacaoFilterDTO,
  Membro,
  PeriodoReferencia,
  AlterarStatusComprovacaoModel,
  Email,
} from '../../model';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { StringUtil } from '../../shared';
import { LoggedUserService, ToastService, User } from '@mpng/home';


@Component({
  selector: 'app-trocar-status',
  templateUrl: './trocar-status.component.html',
  styleUrls: ['./trocar-status.component.css'],
})
export class TrocarStatusComponent implements OnInit {

  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private loggedUserService: LoggedUserService
  ) {
  }


  private comprovacaoFilterDTO: ComprovacaoFilterDTO;

  private matricula;

  private totalRegisters: number;

  private isReadyBusca: boolean = false;

  /*Para a busca pelo nome, com mais de um resultado*/
  private listViewBenef: any[];


  private resultsAutoComplete: Membro[];
  private choiceAutoComplete: Membro;
  private membro: Membro;

  /*Substituindo a ideia de checkbox para dropdown*/
  private statusComprovacaoArray: SelectItem[];
  /*Utilizada para não exibir o status atual como uma das opções de novo status no dropdown*/
  /*TODO - Tentativa de evitar mais chamadas a função verificaStatusAtual(), futuramente analisar novamente*/
  @ViewChild('novoStatus', {static: false}) novoStatus;
  private statusNovo: any [] = [];

  private dadosOperador: User = new class implements User {
    JSESSIONID: string;
    activeRole: string;
    appId: string;
    enabled: boolean;
    login: string;
    matricula: string;
    name: string;
    roles: string[];
  }();

  private emailDialog: boolean = false;
  private email: Email = new Email();

  /*Variável criada para alimentar o método de confirm()*/
  private dadosLinha: any;


  /*Possibilitando a mudança de status após o encerramento do período de comprovação*/
  private periodoRefAll: PeriodoReferencia[];
  private periodoReferencia: PeriodoReferencia;
  private anoAtual: number = new Date().getFullYear();
  private escolheAno: SelectItem[];
  private getAnoFeito: boolean = false;
  private anoComprovacao: string = '3333';


  ngOnInit() {

    

    this.comprovacaoFilterDTO = new ComprovacaoFilterDTO();
    

    this.totalRegisters = 0;

    /*Atualmente preenchendo o ano "hardcoded"*/
    this.escolheAno = [
      {label: (this.anoAtual - 1).toString(), value: this.anoAtual - 1},
      {label: this.anoAtual.toString(), value: this.anoAtual}
    ];

    this.statusComprovacaoArray = [
      {label: 'Documentos Não Enviados', value: 1},
      {label: 'Documentos Enviados', value: 2},
      {label: 'Com Pendência', value: 3},
      {label: 'Pendência em Análise', value: 4},
      {label: 'Recibo Comprovação Enviado', value: 5},
      {label: 'Comprovação Realizada Fora do CAEM', value: 6}
    ];

    this.dadosOperador = this.loggedUserService.getLoggedUser();
    // console.log('dadosOperador: ', this.dadosOperador);

    // console.log('statusComprovacaoArray: ', this.statusComprovacaoArray);

    /*Limpa os campos de e-mail (string vazia '') para facilitar a validação*/
    this.limpaEmail();

    this.comprovacaoAuxilioEducacaoService.viewAnoComprovacao().subscribe(
      result => {
        this.anoComprovacao = result;
        this.comprovacaoFilterDTO.ano = this.anoComprovacao;
      }
    );

  }

  async pesquisar() {

    /*TODO - Temporariamente preenchendo o ano "hardcoded"*/
    //this.comprovacaoFilterDTO.ano = this.anoComprovacao;

    if (!this.comprovacaoFilterDTO.ano) {
      this.toastService.showWarning('Por favor, preencha o ano antes de realizar a busca.');
    } else {

      if (!this.comprovacaoFilterDTO.matricula && !this.choiceAutoComplete) {
        // this.comprovacaoFilterDTO.ano = this.anoBusca.toString();
        this.toastService.showWarning('Por favor, preencha a matrícula ou nome antes de realizar a busca.');
      } else {
        const anoComp = await this.getAnoComprovacao();

        if (this.getAnoFeito) {
          // console.log('pesqdepois');

          this.comprovacaoFilterDTO.page = 0;
          this.comprovacaoFilterDTO.opcaoEnvio = '0';

          /*Testa se a matrícula foi preenchida ou se temos que obter pelo nome da variável "choiceAutoComplete"*/
          if (this.choiceAutoComplete && this.choiceAutoComplete !== undefined) {
            this.matricula = this.choiceAutoComplete.matricula;
            // console.log('matriculaAutoComp: ', this.matricula);
          } else {
            /*Armazena a matrícula para a utilização no método "buildViewlistBeneficiarioByMatricAndPeriodoRef()"*/
            this.matricula = this.comprovacaoFilterDTO.matricula;
            // console.log('matricula: ', this.matricula);
          }
          if (this.matricula && this.matricula !== undefined) {
            // console.log('matriculaPesq: ', this.matricula);
            this.preencheGrid();
          } else {
            this.toastService.showWarning('Favor inserir dados válidos para a pesquisa.');
          }
        } else {
          this.isReadyBusca = false;
        }
        /*Limpa os dados da busca, para evitar o erro ao realizar uma nova busca na mesma tela*/
        this.comprovacaoFilterDTO.matricula = undefined;
        // this.comprovacaoFilterDTO.nomeFuncionario = undefined;
        this.choiceAutoComplete = undefined;
        this.resultsAutoComplete = [undefined];
      }
    }
  }

  /*Retorna o período de referência para utilização no método preencheGrid()*/
  async getAnoComprovacao() {
    const dataAtual: number = new Date().getTime();

    this.periodoRefAll = await this.comprovacaoAuxilioEducacaoService.findPeriodoReferenciaByAno(Number(this.comprovacaoFilterDTO.ano)).toPromise();
    // console.log('periodoRefAll: ', this.periodoRefAll);

    /*TODO - VERIFICAR SE EXISTE A POSSIBILIDADE DE CHEGAR MAIS DE UM PERÍODO DE REFERÊNCIA*/
    this.periodoReferencia = this.periodoRefAll[0];

    /*Testa a mensagem abaixo "Existe mais de um período de referência cadastrado no ano de..."*/
    // result.push(this.periodoReferencia, this.periodoReferencia);

    if (this.periodoRefAll.length === 0) {
      // if (result.length === 0) {
      setTimeout(() => this.toastService.showError('O período informado ainda não existe.'), 1000);
      setTimeout(() => this.toastService.showWarning('Por favor, verifique o período de referência.'), 3000);
      this.getAnoFeito = false;
    } else if (this.periodoRefAll.length > 1) {
      this.toastService.showWarning('Existe mais de um período de referência cadastrado no ano de ' + this.comprovacaoFilterDTO.ano + '.');
      setTimeout(() => this.toastService.showWarning('Por favor, verifique os períodos de referência.'), 3000);
      this.getAnoFeito = false;
    } else {
      this.getAnoFeito = true;
      // console.log('dataFimReabertura: ', this.periodoReferencia);
      if (this.periodoReferencia.dataFimReabertura && Number(this.periodoReferencia.dataFimReabertura) < dataAtual) {
        setTimeout(() => this.toastService.showInfo('O período atual foi encerrado em  ' + this.periodoReferencia.dataFimReaberturaFormated + '.'), 1000);
      }
    }
    // }
    // );
  }


  private checkStatus(rowData, obj) {
    if (obj.value === Number(rowData.comprovacaoBenefViewDTO.idTipoPendencia)) {
      return false;
    } else {
      return true;
    }
  }

  private verificaStatusAtual(rowData) {
    // this.statusNovo = [];
    let statNew = [];
    // console.log('statNov: ', statNew);
    // console.log('typeof statusComprovacaoArray: ', typeof this.statusComprovacaoArray[5]);
    // console.log('statusComprovacaoArray: ', this.statusComprovacaoArray[5]);

    // console.log('idStatusComprovante: ', Number(rowData.comprovacaoBenefViewDTO.idStatusComprovante));

    if (Number(rowData.comprovacaoBenefViewDTO.idTipoPendencia) === 1 || Number(rowData.comprovacaoBenefViewDTO.idTipoPendencia) === 0) {
      statNew = [this.statusComprovacaoArray[5]];
    } else {
      statNew = this.statusComprovacaoArray.filter(this.checkStatus.bind(this, rowData));
    }
    // console.log('typeof statNew: ', typeof statNew);
    // console.log('statNew: ', statNew);

    return statNew;

  }

  private showEmailDialog(rowData: any) {
    if (rowData.comprovacaoBenefViewDTO.status === null) {
      this.toastService.showWarning('Por favor, escolha o novo status antes de prosseguir.');
    } else {
      // console.log( "Membro atual:", this.membro);
      /*A pedido do cliente retiramos */
      this.email.to = this.membro.login + '.mprj.mp.br';
      this.emailDialog = true;
      // console.log('Status: ', rowData.comprovacaoBenefViewDTO.status);
      // console.log('rowData: ', rowData);
      this.dadosLinha = rowData;

    }
    // console.log('dadosLinha', this.dadosLinha);
  }

  /*Confirma com o operador se deseja realmente mudar o status da comprovação*/
  confirm(rowData: any, isEmail: boolean, listViewBenef: any) {
    // console.log( 'rowData: ', rowData );
    // console.log('status: ', rowData.comprovacaoBenefViewDTO.status);

    // const idComprovBenef: number = Number(rowData.comprovacaoBenefViewDTO.id);

    if (rowData.comprovacaoBenefViewDTO.status === null) {
      this.toastService.showWarning('Por favor, escolha o novo status antes de prosseguir.');
    } else {

      let multiDependente: boolean = false;
      const listDependentes: any[] = []; 
      const numDependentes: number[] = [];

      const idComprovWeb: number = Number(rowData.comprovacaoBenefViewDTO.idComprovacaoWeb);
      const nomeDependente: string = rowData.comprovacaoBenefViewDTO.nomeDependente;

      const novoStatus: number = rowData.comprovacaoBenefViewDTO.status;
      const exibeStatus: string = this.statusComprovacaoArray[novoStatus - 1].label;

      const model: AlterarStatusComprovacaoModel = new AlterarStatusComprovacaoModel();
      // const model: AlterarStatusComprovacaoModel[] = [new AlterarStatusComprovacaoModel()];

      // model.matricula=
      model.numDepen = Number(rowData.comprovacaoBenefViewDTO.numDepen);
      model.idPeriodoReferencia = Number(rowData.comprovacaoBenefViewDTO.idPeriodoReferencia);
      model.cdMatricula = rowData.comprovacaoBenefViewDTO.cdMatricula;

      model.idComprovacao = idComprovWeb;
      model.idNovoStatus = novoStatus;
      model.email = this.email;

      if (!isEmail) {
        model.email = null;
      }

      if (isEmail && model.email.to === '') {
        this.toastService.showWarning('Por favor, preencha o destinatário do e-mail.');
      } else if (isEmail && model.email.msg === '') {
        this.toastService.showWarning('Por favor, preencha a mensagem que será enviada no e-mail.');
      } else {


        this.confirmationService.confirm({

          message: 'Você realmente deseja alterar o status para ' + exibeStatus.bold()
            + ' da comprovação de ' + nomeDependente.bold() + '?',

          accept: () => {
            // console.log('model: ', model);
          
            // const result = await this.comprovacaoAuxilioEducacaoService.alterarStatusComprovacao(model).toPromise();
            this.comprovacaoAuxilioEducacaoService.alterarStatusComprovacao(model).subscribe(
              result => {
                // console.log( 'AlteraStatus: ', result );

                // message: "sucess"
                if (result.message === 'sucess') {
                  this.toastService.showSuccess('Status alterado com sucesso.');
                } else {
                  // console.log('Erro AltStat: ', result);
                  this.toastService.showError('Ocorreu um erro na operação. Por favor tente novamente.');
                }
              }
            );
            

            this.emailDialog = false;
            this.limpaEmail();
            // console.log('comprovacaoFilterDTOSAVE: ', this.comprovacaoFilterDTO);
            // this.preencheGrid();
            // console.log( 'MatSave: ', this.matricula );
            // console.log( 'PerRefSave: ', this.periodoReferencia.id );
            setTimeout(() => {
              this.buildViewlistBeneficiarioByMatricAndPeriodoRef(this.matricula, this.periodoReferencia.id);
            }, 200);
          },

          reject: () => {
            // console.log('rjc');
            /*Mantém os dados preenchidos pelo usuário, para que ele não necessite preencher tudo novamente*/
            if (isEmail) {
              this.email.to = model.email.to;
              this.email.cc = model.email.cc;
              this.email.cco = model.email.cco;
              this.email.msg = model.email.msg;

            }
            this.toastService.showWarning('Operação cancelada pelo usuário.');
          }

        });
      }
    }
    // console.log('fora');
    // this.limpaEmail();
  }


  /*Busca o funcionario por matricula*/
  preencheGrid() {
    // console.log('preenche grid');
    if (!this.matricula) {
      this.toastService.showWarning('A pesquisa não retornou dados.');
    } else {
      this.comprovacaoAuxilioEducacaoService.findFuncionarioByMatricula(this.matricula).subscribe(
        result => {
          this.membro = result;
          // console.log('Membro: ', this.membro);
          // console.log('resultsAutoComplete: ', this.resultsAutoComplete);

          if (this.membro) {
            this.buildViewlistBeneficiarioByMatricAndPeriodoRef(this.matricula, this.periodoReferencia.id);
          } else {
            this.isReadyBusca = false;
            this.toastService.showError('Não foi possível encontrar a matrícula informada.');
          }

        });
      // console.log('preenche grid depois');
    }

    // this.isReadyBusca = true;
  }

  /*Busca para trazer o nome dos dependentes*/
  buildViewlistBeneficiarioByMatricAndPeriodoRef(matricula: string, idPeriodoRef: number) {
    this.comprovacaoAuxilioEducacaoService.viewlistBeneficiarioByMatricAndPeriodoRef(StringUtil.getMatriculaAjust(matricula), idPeriodoRef).subscribe(
      result => {
        this.listViewBenef = result;
        // console.log('listViewBenef: ', this.listViewBenef);
        // console.log('listidstat: ', this.listViewBenef[0].comprovacaoBenefViewDTO.idStatusComprovante);

        // for (let i = 0 ; i <= this.listViewBenef.length ; i++) {
        // TODO - Teste novo Status
        //   this.listViewBenef[0].comprovacaoBenefViewDTO.statusComprovante = this.statusComprovacaoArray[5].label;
        //   this.listViewBenef[0].comprovacaoBenefViewDTO.idStatusComprovante = this.statusComprovacaoArray[5].value;
        // }

        if (this.listViewBenef.length > 0) {
          this.isReadyBusca = true;
        } else {
          this.isReadyBusca = false;
          this.toastService.showError('Não foram encontradas comprovações para a matrícula informada.');
        }
      }
    );
  }

  /*Busca o funcionario por nome (com autocomplete)*/
  search(event: any) {
    const text: string = event.query;
    if (text && text.length > 2) {
      // console.log('text', text);
      this.comprovacaoAuxilioEducacaoService.findMembrosByName(text).subscribe(
        result => {
          this.resultsAutoComplete = result;
          // console.log('choiceAutoCompleteAntes: ', this.choiceAutoComplete);
          // console.log('resultsAutoComplete: ', this.resultsAutoComplete);
        });
      // console.log('choiceAutoComplete: ', this.choiceAutoComplete);
    }
  }

  /*Limpa os campos de e-mail (string vazia '') para facilitar a validação*/
  private limpaEmail() {
    this.email = {
      to: '',
      cc: '',
      cco: '',
      msg: '',
    };
  }

  // criaComprovVaziaDependentes() {
  //
  // }


  private  reciboNaoGerado(){
    
    var arr = this.listViewBenef.filter(item=>{
      return item.comprovacaoBenefViewDTO.idStatusComprovante < 5;
    })
    return (arr != null ? arr.length > 0 : false);
  }

  private menorStatusBeneficiarios(){
    var ret: any  = this.listViewBenef.reduce(function (p, v) {
      console.log(p);
      console.log(v);      
      return ( p.comprovacaoBenefViewDTO.idStatusComprovante < v.comprovacaoBenefViewDTO.idStatusComprovante ? p : v );
    });    
     return parseInt(ret.comprovacaoBenefViewDTO.idStatusComprovante);
  }

}
