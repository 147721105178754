import { StatusComprovante } from './status-comprovante.dto';
import { PeriodoReferencia } from './periodo-referencia.dto';
import { OutrosDados } from './comprovacao-outros-dados';

export class ComprovacaoWeb {
  id: number;
  idTipo: number;
  statusComprovante: StatusComprovante;
  periodoReferencia: PeriodoReferencia;
  reciboComprovacao: any[];
  nameArquivo: string;
  emailComprovacao: string;
  outrosDados: OutrosDados;
  outrosDadosJSON: string;
  dataComprovacao: Date;
  pdfMessageEmailComprovacao: any[];
  dataComprovacaoFormated: string;
}

