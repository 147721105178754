import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
  MatButtonModule,
  MatIconModule,
  MatTooltipModule
} from '@angular/material';

import { DialogModule, FileUploadModule, TooltipModule } from 'primeng/primeng';
import { MpPdfViewerModule, MpFileUploadModule } from '@mpng/home';

import { AnexarArquivoComponent } from './anexar-arquivo.component';

@NgModule({
  imports: [
    CommonModule,
    MpPdfViewerModule,
    MpFileUploadModule,
    FileUploadModule,
    DialogModule,
    FileUploadModule,
    TooltipModule,

    // MatButtonModule,
    // MatIconModule,
    // MatTooltipModule
  ],
  declarations: [
    AnexarArquivoComponent
  ],
  exports: [
    AnexarArquivoComponent
  ]
})
export class AnexarArquivoModule {
}
