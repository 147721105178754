export class Comprovacao {
    id: number;
    nmFuncionario: string;
    cdMatricula: string;
    numDepen: string;
    nomeDependente: string;
    idPeriodoRef: number;
    idStatusComprovante: number;
    idTipoComprovante: number;
    idComprovacaoWeb: number;
    qtdAnexo: number;
    status: string;
    anoReferencia: string;
    nomeBenef: string;
    pendente: string;
    situacaoDocumento: string;
    idComprovacaoBenef: string;
    tipoBeneficiario: string;
    dataComprovacaoFormated: string;
}
