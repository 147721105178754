export class CompRenov {
    cursos: Curso[] = [];
    valorReembolso: number;
    statusOutroGenitor: string;
    email: Email;
    outroGenitorTrasporte: Genitor;
    outroGenitorEscola: Genitor;
    pendencias: Pendencias;

    constructor() {
        this.cursos.push(new Curso());
        this.email = new Email();
        this.pendencias = new Pendencias();
        this.valorReembolso = 0.0;
        this.statusOutroGenitor = '';
    }

    public responsavelEscola(): boolean {
        return !this.outroGenitorEscola ? true : !this.outroGenitorEscola.responsavel;
    }

    public responsavelTransporte(): boolean {
        return !this.outroGenitorTrasporte ? true : !this.outroGenitorTrasporte.responsavel;
    }
}

export class Curso {
    nome: string;
    instituicaoEnsino: string;
    dataConclusao: Date;
    codigoCurso: Number;
}

export class Email {
    to: string;
    cc: string;
    cco: string;
    msg: string;
}

export class Genitor {
    matricula: string;
    nome: string;
    numDepend: number;
    idBenf: number;
    idComprovacao: number;
    tipoComprovacao: string;
    email: string;
    outroEmail: string;
    login: string;
    responsavel: boolean;

    constructor() {
        this.responsavel = false;
    }
}

export class Pendencias {
    instEnsino: boolean;
    instTranspote: boolean;
    instOutroGenitor: boolean;
    instConclusaoCurso: boolean;

    constructor() {
        this.instEnsino = false;
        this.instTranspote = false;
        this.instOutroGenitor = false;
        this.instConclusaoCurso = false;
    }
}

export class Comprovacao extends CompRenov {
}

export class Renovacao extends CompRenov {
}

export class OutrosDados {
    comprovacao: Comprovacao;
    renovacao: Renovacao;
    statusBeneficiario: StatusBeneficiario;


    constructor() {
        this.comprovacao = new Comprovacao();
        this.renovacao = new Renovacao();
        this.statusBeneficiario = new StatusBeneficiario();
    }
}

export class StatusBeneficiario {

    isentoComprovacao: boolean;
    ultimaAtualizacao: Date;
    matricula: string;
}
