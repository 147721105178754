import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { LoggedUserService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';

@Component({
  selector: 'app-comprovacao-auxilio-educacao-aviso',
  templateUrl: './comprovacao-auxilio-educacao-aviso.html'
})
export class ComprovacaoAuxilioEducacaoAvisoComponent implements OnInit {

  private ano: string;

  private msgAlertaComprovacao: string = 'ATENÇÃO: A comprovação do auxílio deverá ser realizada apenas uma vez,' +
    ' incluindo o usuário e todos seus dependentes em um envio único.';

  constructor(
    private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private user: LoggedUserService
  ) {
  }


  ngOnInit() {
    if (this.user.getLoggedUser().activeRole === 'Membro') {
      document.getElementById('divAviso').style.display = 'block';
    }

    this.comprovacaoAuxilioEducacaoService.viewAnoComprovacao().subscribe(
      result => {
        this.ano = result;
      }
    );


  }


}
