import { Component, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';
import { AnexarArquivoComponent } from '../../anexar-arquivo/anexar-arquivo.component';
import { GeraPendenciaComponent } from './gera-pendencia/gera-pendencia.component';
import { TrataPendenciaModel } from '../../model/trata-pendencia-model';
import { ComprovacaoFilterDTO, ComprovacaoWeb } from '../../model';

@Component({
  selector: 'app-consulta-pendencia',
  templateUrl: './consulta-pendencia.component.html',
  styleUrls: ['./consulta-pendencia.component.css'],
})
export class ConsultaPendenciaComponent implements OnInit {

  listViewBenef: any[];

  private isReadyLBenfMatrPRef: boolean = false;
  private isReady: boolean = false;
  private isReadyTiposPendencia: boolean = false;
  

  // Altera a exibição dos checkboxes dos tipos de pendencia
  depenIdPendente: number [] = [];


  private idsBenefPendenteProp: number [] = [];
  private idsBenefPendenteDepen: number [] = [];

  // Varíavel que define se deseja-se alterar o tipo de benefício para comprovação ou renovação
  tipoComprovacaoProp: string;
  tipoComprovacaoDepen: string [] = [];

  modelPendenciaBenefProp: TrataPendenciaModel [] = [];
  modelPendenciaBenefDepen: TrataPendenciaModel [] = [];
  // modelPendenciaBenefDepen: TrataPendenciaModel [] = [new TrataPendenciaModel()];

  private pendenteProp: boolean = false;
  private pendenteDepen: boolean = false;

  @Input() comprovacaoView: any;

  private idComprovacaoBenefProp: number;
  private idComprovacaoBenefDep: number [] = [];

  private comprovacaoProprio: any;
  private comprovacaoDependentes: any[];
  private capaComprovacaoWeb: ComprovacaoWeb;
  private temProprio: boolean = false;
  private temDependente: boolean = false;

  private temDependenteStatus: boolean = false;
  private isReadyListBenef: boolean = false;

  private listPendenciaRenderProp: number = 1;
  private listPendenciaRenderDep: number = 1;


  @ViewChild('anexoPendencia', {static: false}) anexoPendencia: AnexarArquivoComponent;
  @ViewChild('anexoReciboComprovacao', {static: false}) anexoReciboComprovacao: AnexarArquivoComponent;
  @ViewChild('geraPendencia', {static: false}) geraPendenciaComponent: GeraPendenciaComponent;

  private messagePendencia: string = '';
  private conteudoEmailreciboComprovacaoWeb: string = '';
  private conteudoEmailreciboComprovacaoWebBeneficiarioProprio: string = '';
  private comprovacaoWeb: ComprovacaoWeb;
  private comprovacaoWebBeneficiarioProprio: ComprovacaoWeb;
  private textoReciboComprovacao: string;

  // tslint:disable-next-line:max-line-length
  private textoReciboPadrao: string = '<p><strong>Prezado(a),</strong></p><p>&nbsp;</p><p><strong>A comprova&ccedil;&atilde;o do seu aux&iacute;lio-educa&ccedil;&atilde;o 2018 foi realizada, conforme recibo em anexo, bem como foi renovado o seu benef&iacute;cio para 2019. <u>Observe atentamente os lan&ccedil;amentos feitos</u>.</strong></p><p>&nbsp;</p><p><strong>Outrossim, informamos que, no caso de dependente universit&aacute;rio,a <u>renova&ccedil;&atilde;o dever&aacute; ser feita semestralmente</u>, ou seja, para que seja gerado o reembolso a partir de julho, dever&aacute; ser encaminhada declara&ccedil;&atilde;o informando que o dependente estar&aacute; matriculado para o segundo semestre. </strong></p><p>&nbsp;</p><p><strong>Frise-se que n&atilde;o ser&aacute; publicado calend&aacute;rio de renova&ccedil;&atilde;o para esse caso, cabendo ao servidor que tiver dependente nessa situa&ccedil;&atilde;o a iniciativa de protocolar a documenta&ccedil;&atilde;o de renova&ccedil;&atilde;o para o segundo semestre, at&eacute; o dia 19 de julho de 2019, a fim de que n&atilde;o haja interrup&ccedil;&atilde;o na percep&ccedil;&atilde;o do benef&iacute;cio.</strong></p><p>&nbsp;</p><p><strong>Att.,</strong></p><p>&nbsp;</p><p><strong>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Servidores</strong></p>';

  constructor(
    private messageService: MessageService,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {
  }

  async ngOnInit() {

    // console.log('comprovacaoViewOnInit: ', this.comprovacaoView);
    // console.log('idStatusMembro: ', this.comprovacaoView.idStatusMembro);
    // console.log('tipoBeneficiario: ', this.comprovacaoView.tipoBeneficiario);

    /*Errado, lista para o usuário*/
    // this.listaBenef();

    // console.log('modelPendenciaBenefProp: ', this.modelPendenciaBenefProp);
    // console.log('modelPendenciaBenefDepen: ', this.modelPendenciaBenefDepen);

    this.buildViewlistBeneficiarioByMatricAndPeriodoRef();

    // if (this.temProprio) {
    //   console.log('prop');
    //   await this.carregaTiposPendencia(this.comprovacaoProprio, null);
    // }
    // if (this.temDependente) {
    //   console.log('dep');
    //   await this.carregaTiposPendencia(null, this.comprovacaoDependentes);
    // }


    /*Todo idComprovacaoWeb precisa ser inserido pelo subscribe desse componente*/
    // this.findComprovacaoWebById(this.comprovacaoView.idComprovacaoWeb);

  }


  buildViewlistBeneficiarioByMatricAndPeriodoRef() {
    this.comprovacaoAuxilioEducacaoService.viewlistBeneficiarioByMatricAndPeriodoRef(this.comprovacaoView.cdMatricula, this.comprovacaoView.idPeriodoReferencia).subscribe(
      result => {
        this.listViewBenef = result;
          //aqui !! 
          let idComprovacao : number = null;
          let idComprovacaoBP : number = null;
          result.filter(f=>{
            return f.comprovacaoBenefViewDTO.idComprovacaoWeb != null && f.comprovacaoBenefViewDTO.idComprovacaoWeb != 0 && f.comprovacaoBenefViewDTO.idComprovacaoWeb != "0";
          }).forEach(element => {
            if(element.comprovacaoBenefViewDTO.tipoBeneficiario === "P"){
              idComprovacaoBP =  element.comprovacaoBenefViewDTO.idComprovacaoWeb;
            }else{
              idComprovacao =  element.comprovacaoBenefViewDTO.idComprovacaoWeb; 
            }
          }); 
          
          this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(idComprovacao).subscribe(
            result2 => {
               this.conteudoEmailreciboComprovacaoWeb = result2.emailComprovacao; 
               this.comprovacaoWeb = result2;
          });

          if(idComprovacaoBP != null){
            this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(idComprovacaoBP).subscribe(
              result2 => {
                this.conteudoEmailreciboComprovacaoWebBeneficiarioProprio = result2.emailComprovacao; 
                this.comprovacaoWebBeneficiarioProprio = result2;
            });
          }
  
          // console.log('listViewBenef: ', this.listViewBenef);

        if (result && result.length > 0) {

          //console.log('tipoBeneficiario: ', result[0].comprovacaoBenefViewDTO.tipoBeneficiario);
          //console.log('buildViewlistBeneficiarioByMatricAndPeriodoRef: ', result);

          /*Testa se existe o benefício próprio*/
          result.forEach(element => {
            if (element.comprovacaoBenefViewDTO.tipoBeneficiario === 'P') {
              this.comprovacaoProprio = element;
              this.temProprio = true;
              this.carregaTiposPendencia(this.comprovacaoProprio, null);
            }  
          });
          

          /*Tendo o benef prop, adiciona o restante dos resultados ao benef dep*/
          if (this.temProprio) {
            this.comprovacaoDependentes = result.filter(element=>{
              return !(element.comprovacaoBenefViewDTO.tipoBeneficiario === 'P');
            }) ;

            if (this.comprovacaoDependentes.length > 0) {
              this.temDependente = true;

              // this.armazenaTiposPendencia('dependente');
              // console.log('comprovacaoDependentes: ', this.comprovacaoDependentes);
              this.carregaTiposPendencia(null, this.comprovacaoDependentes);
            }
            else {
              this.comprovacaoDependentes = null;
              this.temDependente = false;
            }
            
          }

          /*Verificar se existe o benef dep*/
          else {
            this.comprovacaoDependentes = result;
            this.comprovacaoProprio = null;
            this.temDependente = true;
            this.carregaTiposPendencia(null, this.comprovacaoDependentes);

          }
        }

         //console.log('comprovacaoProprio: ', this.comprovacaoProprio);
         //console.log('comprovacaoDependentes: ', this.comprovacaoDependentes);

        this.listaPendencia();
        this.isReadyLBenfMatrPRef = true;
        this.isReady = true;

        /*console.log('listViewBenef: ', this.listViewBenef);*/
      }
    );
  }


  getDependenteValido() : any{
    var arr = this.comprovacaoDependentes.filter(dep=>{
      return dep.comprovacaoBenefViewDTO.idComprovacaoWeb != null && dep.comprovacaoBenefViewDTO.idComprovacaoWeb != 0
      && dep.comprovacaoBenefViewDTO.idComprovacaoWeb != "0";
    })
    if(arr != null){
      return arr.length > 0 ? arr[0] : null;
    }
    return null;
  }

  getIdComprovacaoWebDoDependente(){
    var dep : any  = this.getDependenteValido();
    if(dep != null){
      return dep.comprovacaoBenefViewDTO.idComprovacaoWeb;
    }
  }

  getIdPeriodoReferenciaDoDependente(){
    var dep : any  = this.getDependenteValido();
    if(dep != null){
      return dep.comprovacaoBenefViewDTO.idPeriodoReferencia;
    }
  }

  
  listaPendencia() {
    for (const benef of this.listViewBenef) {
      if (benef.comprovacaoBenefViewDTO.tipoBeneficiario === 'P') {
        this.idComprovacaoBenefProp = benef.comprovacaoBenefViewDTO.id;

        if (benef.comprovacaoBenefViewDTO.pendente === 'S') {
          this.idsBenefPendenteProp.push(benef.comprovacaoBenefViewDTO.id);
          // this.modelPendenciaBenefProp
        }
      } else {
        // this.idComprovacaoBenefDep.push(benef.comprovacaoBenefViewDTO.id);
        // console.log('Aqui');

        if (benef.comprovacaoBenefViewDTO.pendente === 'S') {
          this.idsBenefPendenteDepen.push(benef.comprovacaoBenefViewDTO.id);
          // this.modelPendenciaBenefDepen
          // console.log('Outro Aqui');
        }
      }
    }
    this.testaPendencia();
    // console.log('idComprovacaoBenefDep: ', this.idComprovacaoBenefDep);
    // console.log('idsBenefPendenteProp: ', this.idsBenefPendenteProp);
    // console.log('idsBenefPendenteDepen1: ', this.idsBenefPendenteDepen[0]);
    // console.log('idsBenefPendenteDepen2: ', this.idsBenefPendenteDepen[1]);
  }

  testaPendencia() {
    if (this.idsBenefPendenteProp.length > 0) {
      this.pendenteProp = true;
    }

    if (this.idsBenefPendenteDepen.length > 0) {
      this.pendenteDepen = true;
    }
  }

  onTabClose(event: any) {
    // console.log('onTabClose: ', event);
  }

  selecionaTipoPendencia(id: number) {

    if (this.depenIdPendente.includes(id)) {
      const index = this.depenIdPendente.indexOf(id);
      this.depenIdPendente.splice(index, 1);
    } else {
      this.depenIdPendente.push(id);
    }
    // console.log('depenIdPendente: ', this.depenIdPendente);
  }

  async carregaTiposPendencia(comprovacaoProp?: any, comprovacaoDep?: any []) {
    if (comprovacaoProp && comprovacaoProp.comprovacaoBenefViewDTO.idStatusComprovante !=="6") {
      this.modelPendenciaBenefProp [0] = new TrataPendenciaModel();
      this.tipoComprovacaoProp = '';
      const comprovacaoPropById = await this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(comprovacaoProp.comprovacaoBenefViewDTO.id).toPromise();
      // console.log('comprovacaoPropById: ', comprovacaoPropById);
      if (comprovacaoPropById) {
        // console.log('tem comprovacaoPropById');
        this.modelPendenciaBenefProp[0].idDependente = comprovacaoProp.comprovacaoBenefViewDTO.id;
        this.modelPendenciaBenefProp[0].pendenciasComprovacao.instEnsino = comprovacaoPropById.outrosDados.comprovacao.pendencias.instEnsino;
        this.modelPendenciaBenefProp[0].pendenciasComprovacao.instConclusaoCurso = comprovacaoPropById.outrosDados.comprovacao.pendencias.instConclusaoCurso;

        if (this.modelPendenciaBenefProp[0].pendenciasComprovacao.instEnsino === true || this.modelPendenciaBenefProp[0].pendenciasComprovacao.instConclusaoCurso === true) {
          this.tipoComprovacaoProp = 'C';
        }
      }

    }

    // console.log('comprovacaoDep: ', comprovacaoDep);

    if (comprovacaoDep) {
      let i: number = 0;
      for (const depen of this.comprovacaoDependentes) {
         
        // console.log('depen: ', depen);
        // console.log('modelPendenciaBenefDepen: ', this.modelPendenciaBenefDepen);
        this.modelPendenciaBenefDepen[i] = new TrataPendenciaModel(); 
        if(depen.comprovacaoBenefViewDTO.idStatusComprovante =="6") {
          i += 1; // só pode dar continue depois de criar a "linha vazia" no modelPendenciaBenefDepen senão dá erro de index no html tentando acessar nulo no que vier depois disso
          continue; // não faz nada se a comprovação for feita fora do CAEM
        }
        this.tipoComprovacaoDepen[i] = '';
        // console.log('modelPendenciaBenefDepen: ', this.modelPendenciaBenefDepen);
        // console.log('instEnsino: ', this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instEnsino);

        const comprovacaoDepenById = await this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(depen.comprovacaoBenefViewDTO.id).toPromise();
        // console.log('comprovacaoDepenById: ', comprovacaoDepenById);
        if (comprovacaoDepenById) {  
          // console.log('tem comprovacaoDepenById');
          // console.log('modelPendenciaBenefDepen[' + i + '] :', this.modelPendenciaBenefDepen[i]);
          this.modelPendenciaBenefDepen[i].idDependente = depen.comprovacaoBenefViewDTO.id;
          this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instEnsino = comprovacaoDepenById.outrosDados.comprovacao.pendencias.instEnsino;
          this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instTranspote = comprovacaoDepenById.outrosDados.comprovacao.pendencias.instTranspote;
          this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instOutroGenitor = comprovacaoDepenById.outrosDados.comprovacao.pendencias.instOutroGenitor;
          this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instEnsino = comprovacaoDepenById.outrosDados.renovacao.pendencias.instEnsino;
          this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instTranspote = comprovacaoDepenById.outrosDados.renovacao.pendencias.instTranspote;
          this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instOutroGenitor = comprovacaoDepenById.outrosDados.renovacao.pendencias.instOutroGenitor;

          if (this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instEnsino === true || this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instTranspote === true || this.modelPendenciaBenefDepen[i].pendenciasComprovacao.instOutroGenitor === true) {
            this.tipoComprovacaoDepen[i] = 'C';
          }
          else if (this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instEnsino === true || this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instTranspote === true || this.modelPendenciaBenefDepen[i].pendencuasRenovacao.instOutroGenitor === true) {
            this.tipoComprovacaoDepen[i] = 'R';
          }
        }

        i += 1;
      }
      this.isReadyTiposPendencia = true;
      // console.log('tipoComprovacaoDepen: ', this.tipoComprovacaoDepen);
      // console.log('modelPendenciaBenefDepen: ',  this.modelPendenciaBenefDepen);
    }

  }


  async carregaOutrosDados(id: number) {
    const comprovacaoBenefById = await this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(id).toPromise();
    // console.log('comprovacaoBenefById: ', comprovacaoBenefById);
    return comprovacaoBenefById;
    // this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(id).subscribe(
    //   result => {
    //     console.log('comprovacaoById: ', result);
    //   }
    // );
  }

  statusComprovanteBeneficiarioProprioMaiorOuIgualA5(): boolean {
    return this.comprovacaoWebBeneficiarioProprio != null ? (
      this.comprovacaoWebBeneficiarioProprio.statusComprovante != null ? 
          ( this.comprovacaoWebBeneficiarioProprio.statusComprovante.id != null? this.comprovacaoWebBeneficiarioProprio.statusComprovante.id >= 5 : false) 
      : false
      ) 
    : false;
    
  }
  

}
