import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { FileUploadService } from '@mpng/home';

import {
  ComprovacaoFilterDTO,
  ComprovacaoBenf,
  PeriodoReferencia,
  Funcionario,
  Email,
  AlterarStatusComprovacaoModel,
  ComprovacaoListaAnualDTO,
  ComprovacaoOcorrenciaDTO
} from '../../model';
import { ComprovacaoBenfView } from '../../model/comprovacao-benefview';
import { TrataPendenciaModel, TrataPendenciaPostModel } from '../../model/trata-pendencia-model';


@Injectable()
export class ComprovacaoAuxilioEducacaoService {

  /**
   *
   * @param http
   * @param fileUploadService
   */
  constructor(
    private http: HttpClient,
    private fileUploadService: FileUploadService
  ) {
  }
  
  obtemStatusGeral(): Observable<any> {
    const url = `/api/statusComprovante/selectStatusByMatricAndPeriodoref/`;
    return this.http.get<any>(url);
  }
  
  viewComprovacao(): Observable<any> {
    const url = `/api/view/comprovacao/`;
    return this.http.get<any>(url);
  }

  
  viewComprovacaoDependentes(): Observable<any> {
    const url = `/api/view/comprovacaoDependentes/`;
    return this.http.get<any>(url);
  }

  viewComprovacaoById(): Observable<any> {
    const url = `/api/view/ComprovacaoById/`;
    return this.http.get<any>(url);
  }

  viewComprovacaoRenovacaoAnexo(comprovacaoBenfView: ComprovacaoBenfView, renovacao: Boolean): Observable<any> {
    const url = !renovacao ? `/api/view/comprovacaoAnexo/` : '/api/view/renovacaoAnexo/';
    // console.log('url: ', url);
    // console.log('comprovacaoBenfView: ', comprovacaoBenfView);
    return this.http.post(url, comprovacaoBenfView);
  }

  viewBeneficiosDoBeneficiario(comprovacaoBenfView: ComprovacaoBenfView, renovacao: Boolean): Observable<any> {
    ///beneficiarioPossuiBeneficios
    const url = !renovacao ? `/api/view/comprovacaoAnexo/` : '/api/view/renovacaoAnexo/';
    return this.http.post(url, comprovacaoBenfView);
  }

  saveComprovacaoAnexo(comprovacaoBenfDTO: ComprovacaoBenf, filesDocs: File[], renovacao: Boolean): Observable<any> {
    const url = !renovacao ? `/api/comprovacaoBenef/saveComprovacao/` : `/api/comprovacaoBenef/saveRenovacao/`;
    // console.log('comprovacaoBenfDTO-Save', comprovacaoBenfDTO);
    // console.log('filesDocs: ', filesDocs);
    return this.fileUploadService.postMessageAndUploadModel(url, {other: comprovacaoBenfDTO, message: '', files: filesDocs});
    // return;
  }  

  /*saveComprovacaoAnexoMembro(comprovacaoBenfDTO: ComprovacaoBenf, filesDocs: File[], renovacao: Boolean): Observable<any> {
    const url = !renovacao ? `/api/comprovacaoBenef/saveComprovacaoMembro/` : `/api/comprovacaoBenef/saveRenovacaoMembro/`;
    /!*console.log('comprovacaoBenfDTO-Save', comprovacaoBenfDTO);
    console.log('filesDocs', filesDocs);*!/
    return this.fileUploadService.postMessageAndUploadModel(url, {other: comprovacaoBenfDTO, message: '', files: filesDocs});
    // return;
  }*/

  viewAnexoByID(idAnexo: number) {
    const url = `/api/comprovacaoAnexo/findByID/` + idAnexo;
    return this.http.get<any>(url);
  }

  viewlistBeneficiario(): Observable<any> {
    const url = `/api/view/listBeneficiario/`;
    return this.http.get<any>(url);
  }

  viewlistBeneficiarioByMatricAndPeriodoRef(matricula: string, idPeriodoRef: number): Observable<any> {
    const url = `/api/view/listBeneficiarioByMatricAndPeriodoRef/` + matricula + '/' + idPeriodoRef;
    return this.http.get<any>(url);
  }

  viewBeneficiarioByMatricAndPeriodoRefAndNumDepend(matricula: string, idPeriodoRef: number, numDepend: number): Observable<any> {
    const url = `/api/view/beneficiarioByMatricAndPeriodoRefAndNumDepend/` + matricula + '/' + idPeriodoRef + '/' + numDepend;
    return this.http.get<any>(url);
  }


  viewAnoComprovacao(): Observable<any> {
    const url = '/api/periodoReferencia/anoComprovacao/';
    return this.http.get<any>(url);
  }

  /*
  -------------User Detail-------------
  userDetailLogado(): Observable<any> {
      const url = `/api/userDetailLogado`;
      return this.http.get<any>(url);
  }
  */

  existePeriodoRefAberto() {
    const url = '/api/periodoReferencia/existePeriodoRefAberto';
    return this.http.get<any>(url);
  }

  enviarComprovacao(): Observable<any> {
    const url = '/api/comprovacaoBenef/enviarComprovacao';
    return this.http.get<any>(url);
  }

  enviarComprovacaoBP(): Observable<any> {
    const url = '/api/comprovacaoBenef/enviarComprovacaoBP';
    return this.http.get<any>(url);
  }

  viewfindComprovacao(comprovacaoBenfView: ComprovacaoBenfView, renovacao: Boolean): Observable<any> {
    const url = !renovacao ? `/api/view/comprovacaoAnexo/` : '/api/view/renovacaoAnexo/';
    return this.http.post(url, comprovacaoBenfView);
  }

  findComprovacao(comprovacaoFilterDTO: ComprovacaoFilterDTO): Observable<any> {
    const url = '/api/comprovacaoWeb/findComprovacao';
    return this.http.post(url, comprovacaoFilterDTO);
  }

  findComprovacaoByFilter(comprovacaoFilterDTO: ComprovacaoFilterDTO): Observable<any> {
    const url = '/api/comprovacaoWeb/findComprovacaoByFilter';
    return this.http.post(url, comprovacaoFilterDTO);
  }


  downloadRelatorioComprovacao(comprovacaoFilterDTO: ComprovacaoFilterDTO): Observable<any> {
    const url = '/api/comprovacaoWeb/downloadRelatorioComprovacao';
    return this.http.post(url, comprovacaoFilterDTO, {responseType: 'blob', observe: 'response'});
  }


  saveSituacaoAnexo(lisAnexo: any[]): Observable<any> {
    const url = `/api/comprovacaoAnexo/saveSituacaoAnexo`;
    return this.http.post(url, lisAnexo);
  }

  saveListaAnexoOperador(idBeneficiario: number, idTipoArquivo: number, arquivos: File[]): Observable<any> {
    const url = `/api/comprovacaoAnexo/saveListaAnexoOperador/` + idBeneficiario + '/' + idTipoArquivo;
    return this.fileUploadService.postMessageAndUploadModel(url, {other: '', message: '', files: arquivos});
  }
  
  deletaAnexoOperador(idAnexo: number): Observable<any> {
    const url = `/api/comprovacaoAnexo/deleteAnexoOperador/` + idAnexo;
    return this.http.get<any>(url);
  }

    viewAnexoByBenefAndTipo(idBeneficiario: number, idTipoArquivo: number) {
    const url = `/api/comprovacaoAnexo/findAnexosByBenefAndTipo/` + idBeneficiario + '/' + idTipoArquivo;
    return this.http.get<any>(url);
  }
  
  downloadComprovacaoAnexo(idAnexo: number): Observable<any> {
    const url = '/api/comprovacaoAnexo/downloadComprovacaoAnexo/' + idAnexo;
    return this.http.get(url, {responseType: 'blob', observe: 'response'});
  }


  salvarComprovacaoPendente(idComprovacaoWeb: number, messagePendenciaComprovacao: string, idComprovacaoBenefPendentes: number[], files: File[]) {
    const url = `/api/exigenciaComprovacaoWeb/salvarComprovacaoPendente/` + idComprovacaoWeb + '/' + idComprovacaoBenefPendentes;
    // console.log('salvarComprovacaoPendente URL:' + url );

    return this.fileUploadService.postMessageAndUploadModel(url, {other: '', message: messagePendenciaComprovacao, files: files});
  }


  alterarStatusBeneficiario(idBenef: number, novoStatus: number): Observable<ComprovacaoBenf> {
    const url = `/api/comprovacaoBenef/alterarStatusBeneficiario/` + idBenef + '/' + novoStatus;
    // console.log( 'alteraStatusServ: ', url );
    return this.http.post<ComprovacaoBenf>(url, null);
  }

  alterarStatusComprovacao(dados: AlterarStatusComprovacaoModel): Observable<any> {
    const url = `/api/comprovacaoBenef/alterarStatusBeneficiarioComprovacao`;
    // console.log( 'alterarStatusComprovacao url: ', url );
    // console.log( 'alterarStatusComprovacao  dados: ', dados );
    return this.http.post<any>(url, dados);
  }

  // alterarStatusBeneficiario( idBenef: number, novoStatus: boolean ): Observable<ComprovacaoBenf> {
  //   const url = `/api/comprovacaoBenef/alterarStatusBeneficiario/` + idBenef + '/' + novoStatus;
  //   console.log('alteraStatusServ: ', url);
  //   return this.http.post<ComprovacaoBenf>( url, null );
  // }


  concluirComprovacao(idComprovacaoWeb: number, messageComprovacao: string, files: File[]) {
    const url = `/api/comprovacaoWeb/concluirComprovacao/` + idComprovacaoWeb;
    return this.fileUploadService.postMessageAndUploadModel(url, {other: '', message: messageComprovacao, files: files});
  }

  concluirComprovacaoBP(idComprovacaoWeb: number, messageComprovacao: string, files: File[]) {
    const url = `/api/comprovacaoWeb/concluirComprovacaoBP/` + idComprovacaoWeb;
    return this.fileUploadService.postMessageAndUploadModel(url, {other: '', message: messageComprovacao, files: files});
  }

  concluirComprovacaoBPNew(idComprovacaoWeb: number, email: Email, messageComprovacao: string, files: File[]) {
    const url = `/api/comprovacaoWeb/concluirComprovacaoBP/` + idComprovacaoWeb;
    return this.fileUploadService.postMessageAndUploadModel(url, {other: email, message: messageComprovacao, files: files});
  }

  concluirComprovacaoNew(idComprovacaoWeb: number, email: Email, messageComprovacao: string, files: File[]) {
    const url = `/api/comprovacaoWeb/concluirComprovacao/` + idComprovacaoWeb;
    return this.fileUploadService.postMessageAndUploadModel(url, {other: email, message: messageComprovacao, files: files});
  }

  salvarComprovacaoPendenteNew(idComprovacaoWeb: number, email: Email, messagePendenciaComprovacao: string, idComprovacaoBenefPendentes: number[], files: File[]) {
    const url = `/api/exigenciaComprovacaoWeb/salvarComprovacaoPendente/` + idComprovacaoWeb + '/' + idComprovacaoBenefPendentes;
    // console.log('salvarComprovacaoPendente URL:' + url );

    return this.fileUploadService.postMessageAndUploadModel(url, {
      other: email,
      message: messagePendenciaComprovacao,
      files: files
    });
  }

  salvarComprovacoesPendentesNew(idComprovacaoWeb: number, messagePendenciaComprovacao: string, trataPendencias: TrataPendenciaPostModel, files: File[]) {
    const url = `/api/exigenciaComprovacaoWeb/salvarComprovacoesPendentes/` + idComprovacaoWeb;
    // console.log('salvarComprovacaoPendente URL:' + url );

    // console.log('trataPendenciaPostModel: ', trataPendencias);
    return this.fileUploadService.postMessageAndUploadModel(url, {
      other: trataPendencias,
      message: messagePendenciaComprovacao,
      files: files
    });
  }

  // salvarComprovacoesPendentesNew(idComprovacaoWeb: number, email: Email, messagePendenciaComprovacao: string, trataPendencias: TrataPendenciaModel[], files: File[]) {
  //   const url = `/api/exigenciaComprovacaoWeb/salvarComprovacoesPendentes/` + idComprovacaoWeb + '/' + JSON.stringify(trataPendencias);
  //   // console.log('salvarComprovacaoPendente URL:' + url );
  //
  //   return this.fileUploadService.postMessageAndUploadModel(url, {
  //     other: [email, trataPendencias],
  //     message: messagePendenciaComprovacao,
  //     files: files
  //   });
  // }


  findExigenciaByIdComprovacao(idComprovacaoWeb: number): Observable<any> {
    const url = `/api/exigenciaComprovacaoWeb/findByIdComprovacao/` + idComprovacaoWeb;
    return this.http.get<any>(url);
  }

  findExigenciaById(id: number): Observable<any> {
    const url = `/api/exigenciaComprovacaoWeb/findById/` + id;
    return this.http.get<any>(url);
  }


  findComprovacaoBenefById(id: number): Observable<ComprovacaoBenf> {
    const url = `/api/comprovacaoBenef/findComprovacaoBenefById/` + id;
    return this.http.get<any>(url);
  }

  findComprovacaoWebById(id: number): Observable<any> {
    const url = `/api/comprovacaoWeb/findComprovacaoWebById/` + id;
    return this.http.get<any>(url);
  }


  findPeriodoComprovacaoByAno(ano: String) {
    let url = `/api/periodoReferencia/findPeriodoComprovacaoByAno`;
    if (ano !== undefined) {
      url = url + '/' + ano;
    }
    return this.http.get<any>(url);
  }

  savePeriodoReferencia(periodoReferenciaDTO: PeriodoReferencia): Observable<any> {
    const url = '/api/periodoReferencia/save';
    return this.http.post(url, periodoReferenciaDTO);
  }

  saveReabertura(periodoReferenciaDTO: PeriodoReferencia): Observable<any> {
    const url = '/api/periodoReferencia/saveReabertura';
    return this.http.post(url, periodoReferenciaDTO);
  }

  deletePeriodoReferencia(id: number): Observable<any> {
    const url = '/api/periodoReferencia/delete/' + id;
    return this.http.get<any>(url);
  }


  findFuncionarioByMatricula(matricula: string): Observable<Funcionario> {
    const url = `/api/funcionario/findFuncionarioByMatricula/` + matricula;
    return this.http.get<any>(url);
  }

  findFuncionarioByLogin(login: string): Observable<Funcionario> {
    const url = `/api/funcionario/findFuncionarioByLogin/` + login;
    return this.http.get<any>(url);
  }

  findMembrosByName(nome: string): Observable<Funcionario[]> {
    const url = `/api/funcionario/findMembrosByName/` + nome;
    return this.http.get<any>(url);
  }

  findPeriodoReferenciaAtual(): Observable<PeriodoReferencia> {
    const url = '/api/periodoReferencia/periodoReferenciaAtual/';
    return this.http.get<any>(url);
  }

  findPeriodoReferenciaAll(): Observable<PeriodoReferencia> {
    const url = '/api/periodoReferencia/findAll/';
    return this.http.get<any>(url);
  }

  findPeriodoReferenciaByAno(ano: number): Observable<PeriodoReferencia[]> {
    const url = `/api/periodoReferencia/findPeriodoComprovacaoByAno/` + ano;
    return this.http.get<any>(url);
  }

  /*findListaAnual(ano: number): Observable<ComprovacaoListaAnualDTO[]> {
    const url = `/api/comprovacaoWeb/findComprovacaoListaAnual/` + ano;
    return this.http.get<any>(url);
  }*/

  findComprovacaoListaAnualPaginator(ano: string, page: number): Observable<any> {
    const url = `/api/comprovacaoWeb/findComprovacaoListaAnualPaginator/${ano}/${page}`;
    return this.http.get<any>(url);
  }

  downloadRelatorioListaAnual(ano: string): Observable<any> {
    const url = `/api/comprovacaoWeb/downloadListaAnual/${ano}`;
    return this.http.post(url, ano, {responseType: 'blob', observe: 'response'});
  }

  findOcorrencia(idComprovacaoWeb: number, tipo: string): Observable<ComprovacaoOcorrenciaDTO>{
    const url = `/api/comprovacaoOcorrencia/findByIdCompWebTipo/${idComprovacaoWeb}/${tipo}`;
    return this.http.get<ComprovacaoOcorrenciaDTO>(url);
  }

  salvarOcorrencia(ocorrencia: ComprovacaoOcorrenciaDTO): Observable<any> {
    const url = `/api/comprovacaoOcorrencia/updateOcorrencia`;
    return this.http.post(url, ocorrencia);
  }
}