import { AfterContentChecked, AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastService } from '@mpng/home';

import { AnexarArquivoComponent } from '../../../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioEducacaoService } from '../../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoBenf, Email } from '../../../model';
import { TrataPendenciaModel, TrataPendenciaPostModel } from '../../../model/trata-pendencia-model';

@Component({
    selector: 'app-gera-pendencia',
    templateUrl: './gera-pendencia.component.html',
    styleUrls: ['./gera-pendencia.component.css']
})
export class GeraPendenciaComponent implements OnInit, OnChanges, AfterViewInit, AfterContentChecked {

    @Input() idComprovacaoWeb: any;

    @Input() idPeriodoReferencia: any;

    @Input() anoReferencia: any;

    @Input() tipoBeneficio: string;

    @Input() idStatus: number;

    // @Input() idsBenefPendente: number[] = [];
    // @Input() pendenteProp: boolean = false;
    // @Input() pendenteDepen: boolean = false;

    @Input() idsBenefPendenteProp: number[] = [];
    @Input() idsBenefPendenteDepen: number[] = [];

    @Input() modelPendenciaBenefProp: TrataPendenciaModel[];
    @Input() modelPendenciaBenefDepen: TrataPendenciaModel[];

    @Input() idComprovacaoBenefProp: number;
    @Input() idComprovacaoBenefDep: number[];

    @Input() comprovacaoDependentes: any[];
    @Input() comprovacaoProprio: any;

    private listPendenciaRender: number = 1;


    private atendeComprovacao: string;

    private messagePendencia: string = '';

    private textoReciboComprovacao: string;

    private comprovacaoBenf: ComprovacaoBenf;
    private copiaEmail;
    private copiaOcultaEmail;

    enviarPendenciaProprio: boolean = false;
    enviarPendenciaDependente: boolean = false;

    gerarReciboProprio: boolean = false;
    gerarReciboDependente: boolean = false;

    // tslint:disable:max-line-length
    private textoReciboPadrao: string = '<p><strong>Prezado(a),</strong></p><p>&nbsp;</p><p><strong>A comprova&ccedil;&atilde;o do seu aux&iacute;lio-educa&ccedil;&atilde;o varAno foi realizada, conforme recibo em anexo, bem como foi renovado o seu benef&iacute;cio para varAnoProx. <u>Observe atentamente os lan&ccedil;amentos feitos</u>.</strong></p><p>&nbsp;</p><p><strong>Outrossim, informamos que, no caso de dependente universit&aacute;rio,a <u>renova&ccedil;&atilde;o dever&aacute; ser feita semestralmente</u>, ou seja, para que seja gerado o reembolso a partir de julho, dever&aacute; ser encaminhada declara&ccedil;&atilde;o informando que o dependente estar&aacute; matriculado para o segundo semestre. </strong></p><p>&nbsp;</p><p><strong>Frise-se que n&atilde;o ser&aacute; publicado calend&aacute;rio de renova&ccedil;&atilde;o para esse caso, cabendo ao servidor que tiver dependente nessa situa&ccedil;&atilde;o a iniciativa de protocolar a documenta&ccedil;&atilde;o de renova&ccedil;&atilde;o para o segundo semestre, at&eacute; o dia 19 de julho de 2019, a fim de que n&atilde;o haja interrup&ccedil;&atilde;o na percep&ccedil;&atilde;o do benef&iacute;cio.</strong></p><p>&nbsp;</p><p><strong>Att.,</strong></p><p>&nbsp;</p><p><strong>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Servidores</strong></p>';
    private textoReciboPadraoDeps = '<p>Senhor(a) Membro do MPRJ,</p><p>Agradecemos pelo envio da documenta&ccedil;&atilde;o. Procedemos &agrave; comprova&ccedil;&atilde;o do Aux&iacute;lio Educa&ccedil;&atilde;o varAno e a Renova&ccedil;&atilde;o para varAnoProx com sucesso.</p><p>Respeitosamente,</p><p><strong>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Membros do MPRJ</strong></p>';
    private textoReciboPadraoBP = '<p>Senhor(a) Membro do MPRJ,</p><p>Agradecemos pelo envio da documenta&ccedil;&atilde;o. Procedemos &agrave; comprova&ccedil;&atilde;o do Aux&iacute;lio Educa&ccedil;&atilde;o varAno com sucesso.</p><p>Respeitosamente,</p><p><strong>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Membros do MPRJ</strong></p>';

    @ViewChild('anexoPendencia', { static: false }) anexoPendencia: AnexarArquivoComponent;
    @ViewChild('anexoReciboComprovacao', { static: false }) anexoReciboComprovacao: AnexarArquivoComponent;

    constructor(
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
        private toastService: ToastService,
    ) {
    }

    ngOnInit() {
        // this.findComprovacaoWebById(this.comprovacaoView.idPeriodoReferencia);

        // console.log('modelPendenciaBenefProp: ', this.modelPendenciaBenefProp);
        // console.log('modelPendenciaBenefDepen: ', this.modelPendenciaBenefDepen);

        //console.log('onInit');
        //console.log('idStatus' );
        //console.log(this.idStatus);
        
        // this.enviarPendenciaDependente = true;

        this.findComprovacaoWebById(this.idComprovacaoWeb);
        this.textoReciboPadrao = this.tipoBeneficio === 'P' ? this.textoReciboPadraoBP : this.textoReciboPadraoDeps;

        // console.log( 'idsBenefPendenteProp: ', this.idsBenefPendenteProp );
        // console.log( 'idsBenefPendenteDepen: ', this.idsBenefPendenteDepen );


        // console.log( 'pendenteProp: ', this.pendenteProp );
        // console.log( 'pendenteDepen: ', this.pendenteDepen );

        // console.log('anoReferencia: ', this.anoReferencia);
        // console.log('idComprovacaoWeb: ', this.idComprovacaoWeb);
        // console.log('idPeriodoReferencia: ', this.idPeriodoReferencia);
        // console.log('idsBenefPendente: ', this.idsBenefPendente);

        // console.log('ListIdsBenefPendente: ', this.getListIdsBenefPendente());
        // console.log('idsPendJuntos: ', this.idsBenefPendente);
        // console.log('idsPendProp: ', this.idsBenefPendenteProp);
        // console.log('idsPendDepen: ', this.idsBenefPendenteDepen);

        // console.log('comprovacaoProprio', this.comprovacaoProprio);
    }

    ngOnChanges(changes: SimpleChanges): void {
        //console.log('ngOnChanges');
        this.getListIdsBenefPendente();
    }

    ngAfterViewInit(): void {
        //console.log('ngAfterViewInit');
        // this.enviarPendenciaDependente = true;
    }

    ngAfterContentChecked(): void {
    }

    prepararAmbiente() {
        this.enviarPendenciaProprio = true;
        this.enviarPendenciaDependente = true;
        this.gerarReciboProprio = true;
        this.gerarReciboDependente = true;
    }

    limparAmbiente() {
        this.enviarPendenciaProprio = false;
        this.enviarPendenciaDependente = false;
        this.gerarReciboProprio = false;
        this.gerarReciboDependente = false;
    }


    salvaEmailCopia(id?: number, idDep?: number[]) {

        if (id) {
            this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(id).subscribe(result => {
                if (result) {

                    this.comprovacaoBenf = result;

                    // console.log('idComprovacaoBenef: ', this.idComprovacaoBenef);

                    if (this.copiaEmail) {
                        this.comprovacaoBenf.outrosDados.comprovacao.email.cc = this.copiaEmail;
                    }

                    if (this.copiaOcultaEmail) {
                        this.comprovacaoBenf.outrosDados.comprovacao.email.cco = this.copiaOcultaEmail;
                    }

                    // console.log('comprovacaoBenf:', this.comprovacaoBenf);
                    // console.log('salvaEmail outrosDados: ', this.comprovacaoBenf.outrosDados);
                }
            }
            );
        }
        if (idDep) {
            for (const idDepRep of idDep) {
                this.comprovacaoAuxilioEducacaoService.findComprovacaoBenefById(idDepRep).subscribe(result => {
                    if (result) {

                        this.comprovacaoBenf = result;

                        // console.log('idComprovacaoBenef: ', this.idComprovacaoBenef);

                        if (this.copiaEmail) {
                            this.comprovacaoBenf.outrosDados.comprovacao.email.cc = this.copiaEmail;
                        }

                        if (this.copiaOcultaEmail) {
                            this.comprovacaoBenf.outrosDados.comprovacao.email.cco = this.copiaOcultaEmail;
                        }

                        // console.log('comprovacaoBenf:', this.comprovacaoBenf);
                        // console.log('salvaEmail outrosDados: ', this.comprovacaoBenf.outrosDados);
                    }

                }
                );
            }
        }
    }


    gerarReciboComprovacao() {
        // console.log('gerarReciboComprovacao');
        if (this.tipoBeneficio === 'P') {
            this.gerarReciboProprio = true;
            // console.log('Recibo prop');
            this.gerarReciboComprovacaoBP();
        } else {
            this.gerarReciboDependente = true;
            // console.log('Recibo depen');
            // console.log('gerarReciboDependente: ', this.gerarReciboDependente);
            this.gerarReciboComprovacaoDepen();
        }
    }

    gerarReciboComprovacaoBP() {
        this.confirmationService.confirm({
            key: 'dialogEnviaReciboProp',
            message: 'Tem certeza que deseja concluir o processo de comprovação ?',
            accept: () => {
                /*if (this.isReadyIdPendente) {*/
                /*Validação que verifica a existência de anexos ao concluir a comprovação - De acordo com a solicitação iremos inibir o teste a seguir*/
                /*if (this.anexoReciboComprovacao.filesToUpload == null || this.anexoReciboComprovacao.filesToUpload.length === 0) {
                  this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor.'});
                  // this.toastService.showInfo(('Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor.'));
                  return false;
                }*/
                if (this.anexoReciboComprovacao.filesToUpload.length > 1) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Escolha somente um arquivo para o recibo de comprovação.' });
                    // this.toastService.showInfo(('Escolha somente um arquivo para o recibo de comprovação.'));
                    // this.gerarReciboProprio = false;
                    return false;
                }
                if (this.idsBenefPendenteProp && this.idsBenefPendenteProp.length > 0) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Ao gerar o recibo de comprovação, não pode haver beneficiário(s) pendente(s).' });
                    // this.toastService.showError('Informe qual(is) beneficiário(s) está(ão) pendente(s).');
                    // this.gerarReciboProprio = false;
                    return false;
                }
                // console.log(this.textoReciboComprovacao);
                if (this.textoReciboComprovacao == null || this.textoReciboComprovacao === '') {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor informar o texto da mensagem de envio do Recibo de Comprovação.' });
                    // this.toastService.showInfo(('Favor informar o texto da mensagem de envio do Recibo de Comprovação.'));
                    // this.gerarReciboProprio = false;
                    return false;
                }
                const email: Email = new Email();
                email.cc = this.copiaEmail;
                email.cco = this.copiaOcultaEmail;

                // console.log('textoReciboComprovacao: ', this.textoReciboComprovacao);
                this.comprovacaoAuxilioEducacaoService.concluirComprovacaoBPNew(this.idComprovacaoWeb, email, this.textoReciboComprovacao, this.anexoReciboComprovacao.filesToUpload).subscribe(
                    result => {
                        if (result.errorCode != null) {

                            // this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});

                            this.toastService.showError(result.message);
                        } else {

                            // this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:'Comprovação concluída com sucesso.'});
                            this.toastService.showSuccess('Comprovação concluída com sucesso.');
                            // this.exitTelaAprovacao();
                        }
                        this.salvaEmailCopia(this.idComprovacaoBenefProp, null);
                    });

                // this.gerarReciboProprio = false;
                this.limparAmbiente();
            },
            reject: () => {
                // this.gerarReciboProprio = false;
            }
        });
    }

    gerarReciboComprovacaoDepen() {
        // console.log('Antes Accept', this.gerarReciboDependente);
        this.confirmationService.confirm({
            key: 'dialogEnviaReciboDepen',
            message: 'Tem certeza que deseja concluir o processo de comprovação?',
            accept: () => {
                /*if (this.isReadyIdPendente) {*/
                /*Validação que verifica a existência de anexos ao concluir a comprovação - De acordo com a solicitação iremos inibir o teste a seguir*/
                /*if (this.anexoReciboComprovacao.filesToUpload == null || this.anexoReciboComprovacao.filesToUpload.length === 0) {
                  this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor.'});
                  // this.toastService.showInfo(('Impossível concluir a comprovação, pois não foi anexado o recibo de comprovação para o servidor.'));
                  return false;
                }*/
                // console.log('antes if 1', this.gerarReciboDependente);
                if (this.anexoReciboComprovacao.filesToUpload.length > 1) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Escolha somente um arquivo para o recibo de comprovação.' });
                    // this.toastService.showInfo(('Escolha somente um arquivo para o recibo de comprovação.'));
                    // this.gerarReciboDependente = false;
                    return false;
                }
                // console.log('antes if 2', this.gerarReciboDependente);
                if (this.idsBenefPendenteDepen && this.idsBenefPendenteDepen.length > 0) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Ao gerar o recibo de comprovação, não pode haver beneficiário(s) pendente(s).' });
                    // this.toastService.showError('Informe qual(is) beneficiário(s) está(ão) pendente(s).');
                    // this.gerarReciboDependente = false;
                    return false;
                }
                // console.log(this.textoReciboComprovacao);
                // console.log('antes if 3', this.gerarReciboDependente);
                if (this.textoReciboComprovacao == null || this.textoReciboComprovacao === '') {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor informar o texto da mensagem de envio do Recibo de Comprovação.' });
                    // this.toastService.showInfo(('Favor informar o texto da mensagem de envio do Recibo de Comprovação.'));
                    // this.gerarReciboDependente = false;
                    return false;
                }
                const email: Email = new Email();
                email.cc = this.copiaEmail;
                email.cco = this.copiaOcultaEmail;

                // console.log('textoReciboComprovacao: ', this.textoReciboComprovacao);
                this.comprovacaoAuxilioEducacaoService.concluirComprovacaoNew(this.idComprovacaoWeb, email, this.textoReciboComprovacao, this.anexoReciboComprovacao.filesToUpload).subscribe(
                    result => {
                        if (result.errorCode != null) {

                            // this.messageService.add({key: 'tc',sticky:true,severity:'warn', summary: 'ERROR', detail:result.message});

                            this.toastService.showError(result.message);
                        } else {

                            // this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:'Comprovação concluída com sucesso.'});
                            this.toastService.showSuccess('Comprovação concluída com sucesso.');
                            // this.exitTelaAprovacao();
                        }
                        /*Todo - Ocorreu erro na tentativa de passar os parâmetros como array ou number*/
                        this.salvaEmailCopia(null, this.idComprovacaoBenefDep);
                    });

                // this.gerarReciboDependente = false;
                this.limparAmbiente();
            },
            reject: () => {
                // this.gerarReciboDependente = false;
            }
        });
        // this.gera = false;
    }


    public getListIdsBenefPendente(): number[] {
        if (this.tipoBeneficio === 'P') {
            return this.idsBenefPendenteProp;
        } else {
            return this.idsBenefPendenteDepen;
        }
    }

    gerarPendencia() {
        if (this.tipoBeneficio === 'P') {
            this.enviarPendenciaProprio = true;
            // console.log('pend prop');
            this.gerarPendenciaBP();
        } else {
            this.enviarPendenciaDependente = true;
            // console.log('pend depen');
            this.gerarPendenciaDepen();
        }
    }

    gerarPendenciaBP() {
        // console.log('enviarPendenciaProprio: ', this.enviarPendenciaProprio);
        this.confirmationService.confirm({
            key: 'dialogGeraPendenciaProprio',
            message: 'Tem certeza que deseja incluir uma pendência nessa comprovação?',
            accept: () => {
                // if (this.isReadyIdPendente) {
                if (this.anexoPendencia.filesToUpload.length > 1) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Escolha somente um arquivo por pendência.' });
                    // this.toastService.showError(('Escolha somente um arquivo por pendência.'));
                    // this.enviarPendenciaProprio = false;
                    return false;
                }
                if (this.messagePendencia == null || this.messagePendencia === '') {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor informar o texto da pendência.' });
                    // this.toastService.showError(('Favor informar o texto da pendência.'));
                    // this.enviarPendenciaProprio = false;
                    return false;
                }
                if (!this.idsBenefPendenteProp.includes(this.comprovacaoProprio.comprovacaoBenefViewDTO.id)) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor marcar a caixa "Pendência" deste beneficiário.' });
                    // this.toastService.showError(('Favor informar o texto da pendência.'));
                    // this.enviarPendenciaProprio = false;
                    return false;
                }
                // console.log('modelPendenciaBenefProp: ', this.modelPendenciaBenefProp);
                if (this.idsBenefPendenteProp.includes(this.comprovacaoProprio.comprovacaoBenefViewDTO.id)) {

                    let tipoPendenciaValidado: boolean;
                    tipoPendenciaValidado = this.validaTiposPendencia(this.modelPendenciaBenefProp[0], true);

                    if (tipoPendenciaValidado === false) {
                        this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor selecionar pelo menos um tipo de pendência.' });
                        // this.toastService.showError(('Favor informar o texto da pendência.'));
                        // this.enviarPendenciaProprio = false;
                        return false;
                    }
                }

                const email: Email = new Email();
                email.cc = this.copiaEmail;
                email.cco = this.copiaOcultaEmail;

                // Caso o dependente seja desmarcado da lista de pendentes, zerando os tipos de pendência
                if (!this.idsBenefPendenteProp.includes(this.comprovacaoProprio.comprovacaoBenefViewDTO.id)) {
                    // console.log('modelPendenciaBenefPropAntes: ', this.modelPendenciaBenefProp);
                    this.modelPendenciaBenefProp[0] = this.zeraTiposPendencia(this.modelPendenciaBenefProp[0]);
                    // console.log('modelPendenciaBenefPropDepois: ', this.modelPendenciaBenefProp);
                }

                // console.log('modelPendenciaBenefProp: ', this.modelPendenciaBenefProp);

                const trataPendenciaPostModel: TrataPendenciaPostModel = new TrataPendenciaPostModel();
                trataPendenciaPostModel.email = email;
                trataPendenciaPostModel.pendencias = this.modelPendenciaBenefProp;

                // console.log('trataPendenciaPostModel: ', trataPendenciaPostModel);

                // console.log('idComprovacaoWeb: ', this.idComprovacaoWeb);
                // console.log('messagePendencia: ', this.messagePendencia);
                // console.log('getListIdsBenefPendente: ', this.getListIdsBenefPendente());
                // console.log('filesToUpload: ', this.anexoPendencia.filesToUpload);

                // this.trataTextoPendencia(this.messagePendencia);
                // this.messagePendencia = this.trataTextoPendencia(this.messagePendencia);
                // console.log('messagePendencia: ', this.messagePendencia);
                /*TODO - Método Correto*/
                // console.log('messagePendencia: ', this.messagePendencia);
                this.comprovacaoAuxilioEducacaoService.salvarComprovacoesPendentesNew(this.idComprovacaoWeb, this.messagePendencia, trataPendenciaPostModel, this.anexoPendencia.filesToUpload).subscribe(
                    result => {
                        // console.log('resultSalvarPend: ', result);
                        if (result) {
                            if (result.errorCode != null) {
                                this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message });
                                // this.toastService.showError(result.message);
                            } else {
                                // this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:'Comprovação da pendência salva com sucesso.'});
                                this.toastService.showSuccess('Pendência salva com sucesso.');
                                this.listPendenciaRender += 1;

                                // console.log('listPendenciaRender: ', this.listPendenciaRender);

                                /*this.exitTelaAprovacao();*/
                            }
                            this.salvaEmailCopia(this.idComprovacaoBenefProp, null);
                        }
                    });

                // this.enviarPendenciaProprio = false;
                this.limparAmbiente();
                this.findComprovacaoWebById(this.idComprovacaoWeb);
            },
            reject: () => {
                // this.enviarPendenciaProprio = false;
            }
        });
    }

    gerarPendenciaDepen() {
        // console.log('EntrouGeraPend');
        // console.log('enviarPendenciaDependente: ', this.enviarPendenciaDependente);
        this.confirmationService.confirm({
            key: 'dialogGeraPendenciaDepen',
            message: 'Tem certeza que deseja incluir uma pendência nessa comprovação?',
            accept: () => {
                // if (this.isReadyIdPendente) {
                if (this.anexoPendencia.filesToUpload.length > 1) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Escolha somente um arquivo por pendência.' });
                    // this.toastService.showError(('Escolha somente um arquivo por pendência.'));
                    // this.enviarPendenciaDependente = false;
                    return false;
                }
                if (this.messagePendencia == null || this.messagePendencia === '') {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor informar o texto da pendência.' });
                    // this.toastService.showError(('Favor informar o texto da pendência.'));
                    // this.enviarPendenciaDependente = false;
                    return false;
                }
                if (this.idsBenefPendenteDepen && this.idsBenefPendenteDepen.length === 0) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Informe qual(is) beneficiário(s) está(ão) pendente(s).' });
                    // this.toastService.showError('Informe qual(is) beneficiário(s) está(ão) pendente(s).');
                    // this.enviarPendenciaDependente = false;
                    return false;
                }

                let i = 0;
                const tipoPendenciaValidado: boolean[] = [];
                // let tipoPendenciaValidado: boolean[];
                for (const depen of this.comprovacaoDependentes) {
                    if (this.idsBenefPendenteDepen.includes(depen.comprovacaoBenefViewDTO.id)) {
                        // Teste se pelo menos um tipo de pendência está marcado
                        tipoPendenciaValidado[i] = this.validaTiposPendencia(this.modelPendenciaBenefDepen[i], false);
                        // tipoPendenciaValidado.push(this.validaTiposPendencia(this.modelPendenciaBenefDepen[i], false));
                        // console.log('tipoPendenciaValidado: ', tipoPendenciaValidado);
                    }
                    else {
                        tipoPendenciaValidado[i] = false;
                        // Caso o dependente seja desmarcado da lista de pendentes, zerando os tipos de pendência
                        this.modelPendenciaBenefDepen[i] = this.zeraTiposPendencia(this.modelPendenciaBenefDepen[i]);
                        // console.log('modelPendenciaBenefDepen: ', this.modelPendenciaBenefDepen);
                    }

                    i += 1;
                }
                // console.log('tipoPendenciaValidado: ', tipoPendenciaValidado);

                if (!tipoPendenciaValidado.includes(true)) {
                    this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Favor selecionar pelo menos um tipo de pendência.' });
                    // this.toastService.showError(('Favor informar o texto da pendência.'));
                    return false;
                }

                const email: Email = new Email();
                email.cc = this.copiaEmail;
                email.cco = this.copiaOcultaEmail;

                const trataPendenciaPostModel: TrataPendenciaPostModel = new TrataPendenciaPostModel();
                trataPendenciaPostModel.email = email;
                trataPendenciaPostModel.pendencias = this.modelPendenciaBenefDepen;

                // console.log('trataPendenciaPostModel: ', trataPendenciaPostModel);

                // this.messagePendencia = this.trataTextoPendencia(this.messagePendencia);

                this.comprovacaoAuxilioEducacaoService.salvarComprovacoesPendentesNew(this.idComprovacaoWeb, this.messagePendencia, trataPendenciaPostModel, this.anexoPendencia.filesToUpload).subscribe(
                    result => {
                        // console.log('resultSalvarPend: ', result);
                        if (result.errorCode != null) {
                            this.messageService.add({ key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message });
                            // this.toastService.showError(result.message);
                        } else {
                            // this.messageService.add({key: 'tc',sticky:true,severity:'sucess', summary: 'SUCESSO', detail:'Comprovação da pendência salva com sucesso.'});
                            this.toastService.showSuccess('Pendência salva com sucesso.');
                            this.listPendenciaRender += 1;

                            // console.log('listPendenciaRender: ', this.listPendenciaRender);

                            /*this.exitTelaAprovacao();*/
                        }
                        this.salvaEmailCopia(null, this.idComprovacaoBenefDep);
                    });

                // this.enviarPendenciaDependente = false;
                this.limparAmbiente();
                this.findComprovacaoWebById(this.idComprovacaoWeb);
            },
            reject: () => {
                // this.enviarPendenciaDependente = false;
            }
        });
        // console.log('enviarPendenciaDependente: ', this.enviarPendenciaDependente);
    }

    findComprovacaoWebById(idComprovacaoWeb: number) {
        // console.log( "::findComprovacaoWebById:" + idComprovacaoWeb);
        this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(idComprovacaoWeb).subscribe(
            result => {
                // console.log('findComprovacaoWebById: ', result);
                if (result) {
                    if (result.emailComprovacao == null) {
                        if(result.periodoReferencia != null && result.periodoReferencia.ano != null){
                            this.textoReciboComprovacao = this.textoReciboPadrao.replace('varAno',''+result.periodoReferencia.ano).replace('varAnoProx',''+result.periodoReferencia.anoProx);
                        }else{
                            this.textoReciboComprovacao = this.textoReciboPadrao.replace('varAno','2021').replace('varAnoProx','2022');
                        }
                        
                    } else {
                        this.textoReciboComprovacao = result.emailComprovacao;
                    }
                }
                // console.log('textoReciboComprovacao', this.textoReciboComprovacao);
            }
        );

    }

    // trataTextoPendencia(texto: string): string {
    //   // console.log('texto: ', texto);
    //   let textoTratado: string = texto;
    //   if (textoTratado.includes('<p>')) {
    //     textoTratado = textoTratado.replace(/<p>/g, '');
    //     // console.log('texto<p: ', textoTratado);
    //   }
    //   if (textoTratado.includes('<br>')) {
    //     textoTratado = textoTratado.replace(/<br>/g, '');
    //     // console.log('texto2<br: ', textoTratado);
    //   }
    //   if (textoTratado.includes('</p>')) {
    //     // const string = '</p>';
    //     // textoTratado = textoTratado.split(string).join('<br/>');
    //     textoTratado = textoTratado.replace(/<\/p>/g, '<br><br/>');
    //     // console.log('texto2</p: ', textoTratado);
    //   }
    //   // console.log('textoDepois: ', textoTratado);
    //
    //   textoTratado = '<p>' + textoTratado + '<p/>';
    //
    //   return textoTratado;
    // }

    zeraTiposPendencia(modelPendenciaBenefDepen: TrataPendenciaModel): TrataPendenciaModel {
        // console.log('modelPendenciaBenefDepen: ', modelPendenciaBenefDepen);
        modelPendenciaBenefDepen.pendenciasComprovacao.instEnsino = false;
        modelPendenciaBenefDepen.pendenciasComprovacao.instTranspote = false;
        modelPendenciaBenefDepen.pendenciasComprovacao.instOutroGenitor = false;
        modelPendenciaBenefDepen.pendenciasComprovacao.instConclusaoCurso = false;

        modelPendenciaBenefDepen.pendencuasRenovacao.instEnsino = false;
        modelPendenciaBenefDepen.pendencuasRenovacao.instTranspote = false;
        modelPendenciaBenefDepen.pendencuasRenovacao.instOutroGenitor = false;
        modelPendenciaBenefDepen.pendencuasRenovacao.instConclusaoCurso = false;

        return modelPendenciaBenefDepen;
    }

    validaTiposPendencia(modelPendenciaBenef: TrataPendenciaModel, benefProprio: boolean) {
        /*Próprio*/
        if (benefProprio === true) {
            if (modelPendenciaBenef.pendenciasComprovacao.instEnsino) {
                return true;
            }
            else if (modelPendenciaBenef.pendenciasComprovacao.instConclusaoCurso) {
                return true;
            }
            else {
                return false;
            }
        }
        /*Dependentes*/
        else {
            /*Comprovação*/
            if (modelPendenciaBenef.pendenciasComprovacao.instEnsino) {
                return true;
            }
            else if (modelPendenciaBenef.pendenciasComprovacao.instTranspote) {
                return true;
            }
            else if (modelPendenciaBenef.pendenciasComprovacao.instOutroGenitor) {
                return true;
            }
            /*Renovação*/
            else if (modelPendenciaBenef.pendencuasRenovacao.instEnsino) {
                return true;
            }
            else if (modelPendenciaBenef.pendencuasRenovacao.instTranspote) {
                return true;
            }
            else if (modelPendenciaBenef.pendencuasRenovacao.instOutroGenitor) {
                return true;
            }
            else {
                return false;
            }
        }

    }




}
