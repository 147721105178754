import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewRef } from '@angular/core';
import { ComprovacaoAuxilioEducacaoService } from '../shared/services/comprovacao-auxilio-educacao.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ToastService } from '@mpng/home';
import { ComprovacaoFilterDTO } from '../model';
import { animate, state, style, transition, trigger } from '@angular/animations';

interface StatusComprovacao {
  name: string;
  code: string;
}

interface VisualList {
  name: string;
  code: string;
}


@Component({
  selector: 'app-comprovacao-operador',
  templateUrl: './comprovacao-operador.component.html',
  styleUrls: ['./comprovacao-operador.component.css'],
  /*animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]*/
})
export class ComprovacaoOperadorComponent implements OnInit, OnDestroy {

  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private cd: ChangeDetectorRef
  ) {
  }

  private listViewBenef: any[];
  private comprovacaoFilterDTO: ComprovacaoFilterDTO;
  private totalRegisters: number;
  private listComprovacao: any[] = [];
  private listComprovacaoGrid: any[] = [];

  selectedStatusComprovacao: StatusComprovacao;
  statusComprovacaoArray: StatusComprovacao[];


  private telaAnterior: ComprovacaoOperadorComponent;


  /*RowGroup*/
  private isReady: boolean = false;


  // tslint:disable-next-line:max-line-length
  private textoReciboPadrao: string = '<p><strong>Prezado(a),</strong></p><p>&nbsp;</p><p><strong>A comprova&ccedil;&atilde;o do seu aux&iacute;lio-educa&ccedil;&atilde;o 2018 foi realizada, conforme recibo em anexo, bem como foi renovado o seu benef&iacute;cio para 2019. <u>Observe atentamente os lan&ccedil;amentos feitos</u>.</strong></p><p>&nbsp;</p><p><strong>Outrossim, informamos que, no caso de dependente universit&aacute;rio,a <u>renova&ccedil;&atilde;o dever&aacute; ser feita semestralmente</u>, ou seja, para que seja gerado o reembolso a partir de julho, dever&aacute; ser encaminhada declara&ccedil;&atilde;o informando que o dependente estar&aacute; matriculado para o segundo semestre. </strong></p><p>&nbsp;</p><p><strong>Frise-se que n&atilde;o ser&aacute; publicado calend&aacute;rio de renova&ccedil;&atilde;o para esse caso, cabendo ao servidor que tiver dependente nessa situa&ccedil;&atilde;o a iniciativa de protocolar a documenta&ccedil;&atilde;o de renova&ccedil;&atilde;o para o segundo semestre, at&eacute; o dia 19 de julho de 2019, a fim de que n&atilde;o haja interrup&ccedil;&atilde;o na percep&ccedil;&atilde;o do benef&iacute;cio.</strong></p><p>&nbsp;</p><p><strong>Att.,</strong></p><p>&nbsp;</p><p><strong>Ger&ecirc;ncia de Processos e Benef&iacute;cios de Servidores</strong></p>';


  ngOnInit() {

    this.comprovacaoFilterDTO = new ComprovacaoFilterDTO();
    this.totalRegisters = 0;

    this.statusComprovacaoArray = [
      {name: '.::Selecione::.', code: '0'},
      {name: 'Documentos não enviados', code: '1'},
      {name: 'Documentos Enviados', code: '2'},
      {name: 'Com pendência', code: '3'},
      {name: 'Pendência em análise', code: '4'},
      {name: 'Recibo de comprovação enviado', code: '5'},
      {name: 'Comprovação Realizada Fora do CAEM', code: '6'}
    ];

    this.selectedStatusComprovacao = this.statusComprovacaoArray[0];
    this.comprovacaoFilterDTO.page = 0;

  }


  desabilitaPesquisaData(): boolean {
    return (this.selectedStatusComprovacao.code === '0' || this.selectedStatusComprovacao.code === '1');
  }

  preencheGrid() {
    // console.log('comprovacaoFilterDTO: ', this.comprovacaoFilterDTO);
    this.comprovacaoAuxilioEducacaoService.findComprovacaoByFilter(this.comprovacaoFilterDTO).subscribe(
      result => {
        //console.log('preencheGrid result: ', result);
        // console.log('result totReg: ', result.totalRegisters);
        /*Recebe a quantidade total de registros*/
        this.totalRegisters = result.totalRegisters;
        this.listComprovacaoGrid = result.registers;
        // console.log('listComprovacaoGrid: ', this.listComprovacaoGrid);
        // console.log('result.registers: ', result.registers);
        for (const x of result.registers) {
          this.listComprovacao.push(x);
          // console.log('listComp push: ', this.listComprovacao.push(x));
        }
        if (this.listComprovacao.length === 0) {
          this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'A pesquisa não retornou dados.'});
          // this.toastService.showInfo(('A pesquisa não retornou dados.'));
        }
        this.isReady = true;
      }
    );
  }

  pesquisar() {
    if (this.comprovacaoFilterDTO.ano == null) {
      this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: 'Informe o ano da comprovação.'});
      return false;
    }
    this.comprovacaoFilterDTO.page = 0;
    this.comprovacaoFilterDTO.opcaoEnvio = this.selectedStatusComprovacao.code;
    this.listComprovacao = [];
    // console.log('listComprovacao: ', this.listComprovacao);
    this.preencheGrid();

    // console.log('comprovacaoFilterDTO: ', this.comprovacaoFilterDTO);

    /*Limpa os dados da busca, para evitar o erro que estava ocorrendo*/
    this.comprovacaoFilterDTO.matricula = undefined;
    this.comprovacaoFilterDTO.nomeFuncionario = undefined;
  }

  downloadRelatorioComprovacao() {
    this.comprovacaoAuxilioEducacaoService.downloadRelatorioComprovacao(this.comprovacaoFilterDTO).subscribe(
      result => {
        const link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.download = result.headers.get('fileName');
        link.href = window.URL.createObjectURL(result.body);
        document.body.appendChild(link);
        link.click();
      }
    );
  }

  paginate(event) {
    this.comprovacaoFilterDTO.page = event.page;
    this.preencheGrid();
  }

  /*Todo - Verificar se os métodos ainda precisam ser utilizados*/

  onScroll() {
    this.comprovacaoFilterDTO.page++;
    this.preencheGrid();
  }

  /*printJson(str: any) {
    console.log('printJson', str);
  }*/

  onRowTableCollapse(event?: any) {
    // console.log('ComprovacaoOperadorComponent:onRowTableCollapse:', event );
    setTimeout(() => {
      if (this.cd !== null &&
        this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }

    }, 250);

  }

  ngOnDestroy() {
    // console.log('ComprovacaoOperadorComponent:ngOnDestroy');

    // this.eventListener.unsubscribe();

    // this.cd.detach();

    /*if (!this.cd['destroyed']) {
      this.cd.detectChanges();
    }*/

    /*setTimeout(() => {
      if (this.cd !== null &&
        this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }

    }, 250);*/
  }

}
