export class ComprovacaoFilterDTO {
  ano: String;
  opcaoEnvio: String;
  matricula: string;
  nomeFuncionario: string;
  dataInicio: Date;
  dataFinal: Date;
  page: number;  
}

