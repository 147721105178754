import { TipoBeneficio } from './tipo-beneficio.dto';

export class PeriodoReferencia {

  ano: number;
  anoProx: number;
  dataFim: Date;
  dataFimReabertura: Date;
  dataInicio: Date;
  dataInicioReabertura: Date;
  id: number;
  tipoBeneficio: TipoBeneficio;

  dataFimFormated?: string;
  dataFimReaberturaFormated?: string;
  dataInicioFormated?: string;
  dataInicioReaberturaFormated?: string;
}
