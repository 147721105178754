export class StringUtil {

  public static getMatriculaAjust(matricula: string): string {
    if (matricula === null) {
      return null;
    }
    const mascara: string = '00000000';
    const padded: string = mascara.substring(matricula.length) + matricula;
    return padded;
  }

}
