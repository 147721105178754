import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import {PingService} from './shared/services/ping.service'


import { AppInfo, LoggedUserService } from '@mpng/home';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers:[PingService]
})
export class AppComponent implements AfterViewInit {

  public appInfo: AppInfo =

    {
      name: 'Comprovação de Auxílio Educação para Membros',
      context: '/caem',
      icon: 'mp-icon mp-prh',
      // spinner: {bdColor:'rgba(51, 51, 51, 0.8)', size:'medium',color:'#0d2db7',type:'timer'} //Caso queira mudar o spinner padrão
    };

    title = 'app';
    // constructor(public toast: ToastsManager, vRef: ViewContainerRef) {

  constructor(
    private router: Router,
     private pingService: PingService
  ) {}

  ngAfterViewInit() {
    this.router.navigate(['/login']);
    this.pingService.ping();
    
  }
  

}
