import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { ToastService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../shared/services/comprovacao-auxilio-educacao.service';
import { PeriodoReferencia } from '../model';

@Component({
  selector: 'app-comprovacao-gestor',
  templateUrl: './comprovacao-gestor.component.html',
  styleUrls: ['./comprovacao-gestor.component.css']
})
export class ComprovacaoGestorComponent implements OnInit {

  ano: string;
  dataInicio: Date;
  dataFinal: Date;
  dataInicioReabertura: Date;
  dataFinalReaAbertura: Date;
  anoReferencia: number;
  listPeriodoReferencia: PeriodoReferencia[];
  idPeriodoRef: number;
  periodoReferenciaDTO: PeriodoReferencia;

  constructor(
    private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.periodoReferenciaDTO = new PeriodoReferencia();
    this.grid();
  }

  novo() {
    this.periodoReferenciaDTO = new PeriodoReferencia();
    document.getElementById('divPesquisa').style.display = 'none';
    document.getElementById('divSalvar').style.display = 'block';
  }

  grid() {
    document.getElementById('divSalvarReabertura').style.display = 'none';
    document.getElementById('divSalvar').style.display = 'none';
    document.getElementById('divPesquisa').style.display = 'block';
    this.findPeriodoComprovacaoByAno();
  }

  findPeriodoComprovacaoByAno() {
    this.comprovacaoAuxilioEducacaoService.findPeriodoComprovacaoByAno(this.ano).subscribe(
      result => {
        this.listPeriodoReferencia = result;
        /*console.log('listPeriodoReferencia', this.listPeriodoReferencia);*/
      }
    );
  }

  /**
   *
   * @param idPeriodoReferencia
   */
  deletePeriodoReferencia(idPeriodoReferencia: number) {
    this.comprovacaoAuxilioEducacaoService.deletePeriodoReferencia(idPeriodoReferencia).subscribe(
      result => {
        /*console.log('result', result);*/
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        } else {
          this.grid();
        }
      }
    );
  }

  savePeriodoReferencia() {
    /*console.log('this.anoReferencia', this.anoReferencia);*/
    if (this.periodoReferenciaDTO.dataInicio === undefined || this.periodoReferenciaDTO.dataFim === undefined || this.periodoReferenciaDTO.ano === undefined) {
      this.toastService.showError('Informe a data de início, data final e ano de referência');
      return false;
    }
    this.comprovacaoAuxilioEducacaoService.savePeriodoReferencia(this.periodoReferenciaDTO).subscribe(
      result => {
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        } else {
          this.ano = '';
          this.grid();
        }
      }
    );
  }

  updateReabertura() {
    if (this.periodoReferenciaDTO.dataInicioReabertura === undefined || this.periodoReferenciaDTO.dataFimReabertura === undefined) {
      this.toastService.showError('Informe a data de início de reabertura e data final de reabertura');
      return false;
    }
    this.comprovacaoAuxilioEducacaoService.saveReabertura(this.periodoReferenciaDTO).subscribe(
      result => {
        if (result.errorCode != null) {
          this.toastService.showError(result.message);
        } else {
          this.ano = '';
          this.grid();
        }
      }
    );
  }

  editPeridoReabertura(id: number, timerDataInicio: number, timerDataFinal: number, timerDataInicioReabertura: number, timerDataFinalAbertura: number, anoReferencia: number) {
    this.periodoReferenciaDTO = new PeriodoReferencia();
    this.periodoReferenciaDTO.id = id;
    this.periodoReferenciaDTO.dataInicio = new Date(timerDataInicio);
    this.periodoReferenciaDTO.dataFim = new Date(timerDataFinal);
    if (timerDataInicioReabertura != null) {
      this.periodoReferenciaDTO.dataInicioReabertura = new Date(timerDataInicioReabertura);
    }
    if (timerDataFinalAbertura != null) {
      this.periodoReferenciaDTO.dataFimReabertura = new Date(timerDataFinalAbertura);
    }
    this.periodoReferenciaDTO.ano = anoReferencia;
    document.getElementById('divPesquisa').style.display = 'none';
    document.getElementById('divSalvarReabertura').style.display = 'block';
  }

}
