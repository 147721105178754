import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ToastService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../shared/services/comprovacao-auxilio-educacao.service';


@Component({
  selector: 'app-comprovacao-auxilio-educacao-listpendencia',
  templateUrl: 'comprovacao-auxilio-educacao-listpendencia.html',
})
export class ComprovacaoAuxilioEducacaoListPendenciaComponent implements OnInit, OnChanges {

  private anexo: any;
  private title: string;
  @Input() idComprovacaoWeb: number;
  private displayPdf: boolean = false;
  @Input() listExigenciaComprov: any [] = [];

  @Input() listPendenciaRender: number;


  /**
   *
   */
  ngOnInit() {

    // console.log('idComprovacaoWeb', this.idComprovacaoWeb);
    // console.log('ngOnInitRender: ', this.listPendenciaRender);

  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log('ngOnChangesRender: ', this.listPendenciaRender);
    if (this.idComprovacaoWeb == null) {
      return;
    }
    this.comprovacaoAuxilioEducacaoService.findExigenciaByIdComprovacao(this.idComprovacaoWeb).subscribe(
      result => {
        this.listExigenciaComprov = result;
        // console.log('listExigenciaComprov: ', result);
      });
  }


  /**
   *
   * @param comprovacaoAuxilioEducacaoService
   * @param toastService
   */
  constructor(
    private  comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private toastService: ToastService
  ) {
  }


  /**
   *
   * @param id
   */
  showExigencia(id: number) {
    this.comprovacaoAuxilioEducacaoService.findExigenciaById(id).subscribe(
      result => {
        this.anexo = result.arquivoEmail;
        this.displayPdf = true;
      });
  }

  /**
   *
   * @param id
   */
  showHtmlExigencia(id: number) {
    this.comprovacaoAuxilioEducacaoService.findExigenciaById(id).subscribe(
      result => {
        this.anexo = result.pdfEmailExigencia;
        // console.log('anexo: ', this.anexo);
        this.displayPdf = true;
      });
  }


}








