import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuardService, LoginComponent } from '@mpng/home';

import { ComprovacaoOperadorComponent } from './comprovacao-operador/comprovacao-operador.component';
import { ComprovacaoGestorComponent } from './comprovacao-gestor/comprovacao-gestor.component';
import { TrocarStatusComponent } from './comprovacao-operador/trocar-status/trocar-status.component';
import { ListaAnualComponent } from './comprovacao-operador/lista-anual/lista-anual.component';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/comprovacao-auxilio-educacao-aviso',
    pathMatch: 'full'
  },

  {
    path: 'login',
    component: LoginComponent,
    data: {title: 'Login', icon: 'mdi mdi-login', noMenu: true}
  },

  {
    path: 'comprovacao-auxilio-educacao-operador',
    component: ComprovacaoOperadorComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Comprovação Auxílio-Educação Consulta',
      icon: 'mp-icon mp-comprovacao-auxilio-consulta',
      info: '.',
      roles: ['gestor auxílio educação', 'operador'],
    }
  },

  {
    path: 'comprovacao-auxilio-educacao-operador-mudar-status',
    component: TrocarStatusComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Comprovação Auxílio-Educação Mudar Status da Comprovação',
      icon: 'fas fa-exchange-alt fa-3x',
      info: '.',
      roles: ['gestor auxílio educação', 'operador'],
    }
  },

  {
    path: 'lista-anual',
    component: ListaAnualComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Lista Anual de Comprovação',
      icon: 'fas fa-exchange-alt fa-3x',
      info: '.',
      roles: ['gestor auxílio educação', 'operador'],
    }
  },

  {
    path: 'comprovacao-auxilio-educacao-gestor',
    component: ComprovacaoGestorComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Comprovação Auxílio-Educação Período de Referência',
      icon: 'mp-icon mp-periodo',
      info: '',
      roles: ['gestor auxílio educação'],
    }
  },


];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {useHash: true})],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {
}
