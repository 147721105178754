import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';

import { ComprovacaoBenfView } from '../../model/comprovacao-benefview';
import { BeneficioAtivoDependenteView } from '../../model/beneficio-ativo-dependente-view';


import { AnexarArquivoComponent } from '../../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoBenf, OutrosDados, Funcionario, Genitor, StatusComprovacaoENUM, Pendencias } from '../../model/';
import { ListaAnexoComponent } from '../../shared/lista-anexo/lista-anexo.component';

@Component({
  selector: 'app-anexar-comprov-dep',
  templateUrl: './anexar-comprov-dep.component.html',
  styleUrls: ['./anexar-comprov-dep.component.css']
})
export class AnexarComprovDepComponent implements OnInit {

  @Input() comprovacaoBenfView: ComprovacaoBenfView;
  @Input() renovacao: boolean;
  @Input() index: number;
  @Input() last: Boolean = false;
  @Input() listViewBenef: any[];
  @Input() beneficioAtivoDependente: BeneficioAtivoDependenteView;


  @Input() renovacaoComponent: AnexarComprovDepComponent;

  private comprovacaoOutroGenitorTransporte: Genitor;
  private comprovacaoOutroGenitorEscola: Genitor;
  private renovacaoOutroGenitorTransporte: Genitor;
  private renovacaoOutroGenitorEscola: Genitor;

  private msgComprovacaoEscolaOutroGenitor: string;
  private msgComprovacaoTransporteOutroGenitor: string;
  private msgRenovacaoEscolaOutroGenitor: string;
  private msgRenovacaoTransporteOutroGenitor: string;
  private idTipoAnexoComprovacaoInstituicao : number = 1;

  private viewComprovacao: any;


  private dialogId: string;

  private viewComprovacaoAnexo: any;

  private flagAlteracao: boolean = false;

  /*Acerta o lifecycle do component, evitando que o componente tente exibir conteúdo que ainda não foi carregado*/
  private isReadyBuildViewComprovacao: boolean = false;
  private isReadyBuildComprovacaoAnexo: boolean = false;


  private renovValorOutroGenitor: number;
  private statusOutroGenitor: string;

  /*Todo - inicialmente vamos apenas exibir o nome do outro genitor, futuramente pode-se usar o campo abaixo para que o nome possa ser inserido pelo usuário*/
  private nomeOutroGenitor: string;
  private existeNomeOutroGenitor: boolean = false;

  /*As variáveis abaixo serão utilizadas no método getStatusComprovacao()*/
  private statusComprovacao: number;
  private testaStatusComp: boolean = false;
  private isPendente: string;
  private mostrarOutroGenitorRenov: boolean = true;
  private mostrarOutroGenitor: boolean = true;



  // private testaStatusOutroGenitor: boolean = true;

  // cor = ['verde', '#34A835', 'amarelo', '#ffba01'];
  // corPendente =
  // tipoPendencia = ['Inst Ensino', 'Transp'];
  // headerPendencia: string [] = ['pi pi-check'];
  // teste = {'background-color': '#34A835'};

  tiposPendenciaComprov: Pendencias = new Pendencias();
  tiposPendenciaRenov: Pendencias = new Pendencias();

  comprovIcon: string [] = [];
  comprovTip: string [] = [];
  renovIcon: string [] = [];
  renovTip: string [] = [];




  @ViewChild('anexoDocInstituicao', {static: false}) anexoDocInstituicao: AnexarArquivoComponent;
  @ViewChild('anexosTransporte', {static: false}) anexosTransporte: AnexarArquivoComponent;
  @ViewChild('anexosDeclaracaoEmpregador', {static: false}) anexosDeclaracaoEmpregador: AnexarArquivoComponent;
  @ViewChild('listagemAnexoInstituicao', {static: false}) listagemAnexoInstituicao: ListaAnexoComponent;
  @ViewChild('listagemAnexoTransporte', {static: false}) listagemAnexoTransporte: ListaAnexoComponent;
  @ViewChild('listagemAnexoDeclServidor', {static: false}) listagemAnexoDeclServidor: ListaAnexoComponent;


  /**
   *
   * @param dialog
   * @param comprovacaoAuxilioEducacaoService
   * @param messageService
   * @param confirmationService
   * @param router
   */

  constructor(
    // private dialog: MatDialog,
    // private dialog: Dialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {
  }

  ngOnInit() {
    this.buildComprovacaoAnexo();
    //        this.comprovacaoOutroGenitorTransporte.responsavel=false;
    //        this.comprovacaoOutroGenitorEscola.responsavel=false;
    //        this.renovacaoOutroGenitorEscola.responsavel=false;
    //        this.renovacaoOutroGenitorTransporte.responsavel=false;

    this.dialogId = this.renovacao ? 'dialogRenovacao' + this.index : 'dialogComprovacao' + this.index;

    // console.log('listViewBenefDEP: ', this.listViewBenef);
    // console.log('anexoDocInstituicao: ', this.anexoDocInstituicao);

    // console.log('comprovacaoBenfViewDEP: ', this.comprovacaoBenfView);
    // console.log('comprovacaoBenfViewDEP: ', this.comprovacaoBenfView.pendente);
    this.verificarOutroGenitor();


  }

  getNomeFuncbyMatric(matric: string) {
    if (matric) {
      this.comprovacaoAuxilioEducacaoService.findFuncionarioByMatricula(matric).subscribe(
        result => {

          if (result) {
            this.nomeOutroGenitor = result.nome;
            this.existeNomeOutroGenitor = true;
          } else {
            alert('Matrícula não encontrada.');
          }

          // console.log( 'result: ', result );
          /*console.log('nomeOutroGenitor: ', this.nomeOutroGenitor);*/
        }
      );
    } else {
      alert('Digite a matrícula.');
    }
  }

  public buildComprovacaoAnexo() {
    this.flagAlteracao = false;
    this.comprovacaoAuxilioEducacaoService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView, this.renovacao).subscribe(
      result => {
        // console.log( 'result comprovacaoBenfViewt: ', result );
        // console.log('renovacao: ', this.renovacao);
        this.viewComprovacaoAnexo = result;
        // console.log('viewComprovacaoAnexo: ', this.viewComprovacaoAnexo);

        if (result.comprovacaoBenef.outrosDadosJSON !== null) {
          const outrosDados: OutrosDados = JSON.parse(result.comprovacaoBenef.outrosDadosJSON);
          // console.log('buildComprovacaoAnexo:outrosDados=>', outrosDados );
          this.renovValorOutroGenitor = outrosDados.renovacao.valorReembolso;

          /*Todo - Realizar alteração aqui*/
          // this.statusOutroGenitor = outrosDados.comprovacao.statusOutroGenitor;
          this.statusOutroGenitor = this.viewComprovacaoAnexo.comprovacaoBenef.comprovMatriculaConjugueExiste;




          // console.log('outrosDadosJson: ', this.outrosDadosJson);
          // console.log('nomeCurso: ', this.nomeCurso);
          // console.log('instEnsino: ', this.instEnsino);
        } else {
          //                    this.comprovacaoOutroGenitorTransporte = new Genitor();
          //                    this.comprovacaoOutroGenitorEscola = new Genitor();
          //                    this.renovacaoOutroGenitorEscola = new Genitor();
          //                    this.renovacaoOutroGenitorTransporte = new Genitor();
        }

        /*if ( this.statusOutroGenitor === 'M' ) {
            this.testaStatusOutroGenitor = false;
        }*/

        if (this.anexoDocInstituicao != null) {
          this.anexoDocInstituicao.filesToUpload = [];
        }
        if (this.anexosTransporte != null) {
          this.anexosTransporte.filesToUpload = [];
        }

        /*Todo - verificar se será necessário alguma alteração por conta da nova opção Monoparental*/
        if (this.anexosDeclaracaoEmpregador != null) {
          this.anexosDeclaracaoEmpregador.filesToUpload = [];
        }

        this.tiposPendenciaComprov = this.viewComprovacaoAnexo.comprovacaoBenef.outrosDados.comprovacao.pendencias;
        this.tiposPendenciaRenov = this.viewComprovacaoAnexo.comprovacaoBenef.outrosDados.renovacao.pendencias;
        // console.log('dados back: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovacaoWeb.statusComprovante.id);
        // console.log('viewComprovacaoAnexo', this.viewComprovacaoAnexo);
        this.testaTiposPendencia(this.tiposPendenciaComprov, this.tiposPendenciaRenov);

        this.buildViewComprovacao();
        this.verificarOutroGenitor();
        // console.log('viewComprovacaoAnexo: ', this.viewComprovacaoAnexo);

        this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao = 'A';
        this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoTransp = 'A';
        this.isReadyBuildComprovacaoAnexo = true;
      }
    );

  }

  buildViewComprovacao() {
    this.comprovacaoAuxilioEducacaoService.viewComprovacaoDependentes().subscribe(
      result => {
        this.viewComprovacao = result;
        this.isReadyBuildViewComprovacao = true;
        // console.log('viewComprovacao: ', this.viewComprovacao);
      }
    );
    this.getStatusComprovacao();
  }

  /*As variáveis abaixo serão utilizadas para testar o status da comprovação e bloquear os componentes evitando que o usuário realize alterações fora do período permitido*/
  public getStatusComprovacao() {
    // console.log('statusComprovacao2: ', this.statusComprovacao);
    // this.statusComprovacao = this.viewComprovacaoAnexo.comprovacaoBenef.comprovacaoWeb.statusComprovante.id;
    this.isPendente = this.viewComprovacaoAnexo.comprovacaoBenef.isPendente;

    if (this.statusComprovacao === StatusComprovacaoENUM.TODOS.valueOf() || this.statusComprovacao === StatusComprovacaoENUM.DOCUMENTOS_NAO_ENVIADOS.valueOf()) {

      this.testaStatusComp = true;
      // console.log('flag alteraçao: ', this.flagAlteracao);
    }
    else if (this.statusComprovacao === StatusComprovacaoENUM.COM_PENDENCIA.valueOf() && this.isPendente === 'S') {
      this.testaStatusComp = true;

    }
    else {
      this.testaStatusComp = false;

      // console.log('status 5');
      // console.log('flag alteraçao: ', this.flagAlteracao);
    }
    // console.log('statusComprovacao: ', this.statusComprovacao);
    // console.log('testaStatusComp: ', this.testaStatusComp);
  }

  public devePermitirAnexarArquivo( pendencia: boolean){
    return (
      (this.statusComprovacao.valueOf() === StatusComprovacaoENUM.TODOS.valueOf()
        || this.statusComprovacao.valueOf() === StatusComprovacaoENUM.DOCUMENTOS_NAO_ENVIADOS.valueOf()
        || (this.statusComprovacao.valueOf() === StatusComprovacaoENUM.COM_PENDENCIA.valueOf()  && pendencia ))
       );
  }

  /*TODO - Marcão!!!*/
  testaTiposPendencia(tiposPendenciaComprov: Pendencias, tiposPendenciaRenov: Pendencias) {
    // console.log('tiposPendenciaRenov: ', tiposPendenciaRenov);
    this.statusComprovacao = this.viewComprovacaoAnexo.comprovacaoBenef.comprovacaoWeb.statusComprovante.id;
    // console.log('statusComprovacao: ', this.statusComprovacao);
    // Comprovação
    if (tiposPendenciaComprov.instEnsino === true) {
    // if (tiposPendenciaComprov.instEnsino === true) {
      // this.comprovIcon.push('pi pi-times');
      this.comprovIcon.push('pi pi-exclamation-triangle');
      this.comprovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.comprovIcon.push('pi pi-check');
      this.comprovTip.push('');
    }
    else {
      this.comprovIcon.push('');
      this.comprovTip.push('');
    }
    if (tiposPendenciaComprov.instTranspote === true) {
      // this.comprovIcon.push('pi pi-times');
      this.comprovIcon.push('pi pi-exclamation-triangle');
      this.comprovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.comprovIcon.push('pi pi-check');
      this.comprovTip.push('');
    }
    else {
      this.comprovIcon.push('');
      this.comprovTip.push('');
    }
    if (tiposPendenciaComprov.instOutroGenitor === true) {
      // this.comprovIcon.push('pi pi-times');
      this.comprovIcon.push('pi pi-exclamation-triangle');
      this.comprovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.comprovIcon.push('pi pi-check');
      this.comprovTip.push('');
    }
    else {
      this.comprovIcon.push('');
      this.comprovTip.push('');
    }
    // console.log('comprovIcon', this.comprovIcon);
    // console.log('comprovTip', this.comprovTip);

    // Renovação
    if (tiposPendenciaRenov.instEnsino === true) {
      this.renovIcon.push('pi pi-exclamation-triangle');
      this.renovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.renovIcon.push('pi pi-check');
      this.renovTip.push('');
    }
    else {
      this.renovIcon.push('');
      this.renovTip.push('');
    }
    if (tiposPendenciaRenov.instTranspote === true) {
      this.renovIcon.push('pi pi-exclamation-triangle');
      this.renovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.renovIcon.push('pi pi-check');
      this.renovTip.push('');
    }
    else {
      this.renovIcon.push('');
      this.renovTip.push('');
    }
    if (tiposPendenciaRenov.instOutroGenitor === true) {
      this.renovIcon.push('pi pi-exclamation-triangle');
      this.renovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.renovIcon.push('pi pi-check');
      this.renovTip.push('');
    }
    else {
      this.renovIcon.push('');
      this.renovTip.push('');
    }
    // console.log('renovIcon', this.renovIcon);
    // console.log('renovTip', this.renovTip);

  }


  /*Todo - Concluir a implementação abaixo*/
  exibeNomeGenitor(matricula: string) {
    this.comprovacaoAuxilioEducacaoService.findFuncionarioByMatricula(matricula).subscribe(
      result => {
        this.nomeOutroGenitor = result.nome;
      }
    );
  }

  /*Conforme solicitação, o método abaixo define que caso seja escolhida a opção monoparental em comprovação, que não seja exibida a opção outro genitor em renovação*/

  /*testaOutroGenitor( statusGen: string ) {
      // console.log('Testando status: ', statusGen);
      if ( statusGen === 'M' ) {
          this.testaStatusOutroGenitor = false;
      } else {
          this.testaStatusOutroGenitor = true;
      }
      this.renovacaoComponent.testaStatusOutroGenitor = this.testaStatusOutroGenitor;
      // console.log('testaStatusOutroGenitor: ', this.testaStatusOutroGenitor);
  }*/


  updateFlagAlteracao(): void {
    if (this.testaStatusComp) {
      this.flagAlteracao = true;
      // console.log('flag alteraçao: ', this.flagAlteracao);
    } else {
      this.flagAlteracao = false;
      // console.log('flag alteraçao: ', this.flagAlteracao);
    }
    this.verificarOutroGenitor();
    // console.log('comprovMatriculaConjugueExiste: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovMatriculaConjugueExiste);
    //console.log('teste1: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoTransp=='R' );
    //console.log('teste2: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao=='R');
}

  verificarOutroGenitor(): void{
        //console.log('verificarOutroGenitor: ', this.viewComprovacaoAnexo);
        //console.log('Benefício ativo dependente: ', this.beneficioAtivoDependente);
        if (this.viewComprovacaoAnexo) {
          if(this.beneficioAtivoDependente.possuiTransporte && this.beneficioAtivoDependente.possuiEducacao){
            this.mostrarOutroGenitorRenov = !((this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoTransp=='N') &&
                                              (this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoInstituicao=='N')
                                             )
          } else if(!this.beneficioAtivoDependente.possuiTransporte && this.beneficioAtivoDependente.possuiEducacao){
            this.mostrarOutroGenitorRenov = !(this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoInstituicao=='N')
          } else if(this.beneficioAtivoDependente.possuiTransporte && !this.beneficioAtivoDependente.possuiEducacao){
            this.mostrarOutroGenitorRenov = !(this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoTransp=='N')
          } else{
            console.log('verificarOutroGenitor: Situação não esperada');
          }
        }
        //this.mostrarOutroGenitor = !((this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoTransp=='R') && (this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao=='R'))
    }

  public cancel() {
    this.buildComprovacaoAnexo();
  }

  public existeAlteracao(): boolean {
    return this.flagAlteracao;
  }

  testaPendenciaDependente(comprovacaoBenfDTO: ComprovacaoBenf) {
    if (comprovacaoBenfDTO.isPendente === 'N') {
      this.confirmationService.confirm({
        message: 'Este dependente não está com pendência. Tem certeza que deseja adicionar a documentação?',
        accept: () => {
          this.prepareSaveComprovacao(comprovacaoBenfDTO);
        },
        reject: () => {
          this.messageService.add({
            key: 'tc',
            sticky: true,
            severity: 'warning',
            summary: 'Cancelado',
            detail: 'A operação foi cancelada pelo usuário.'
          });
        }
      });
    }
    else {
      this.prepareSaveComprovacao(comprovacaoBenfDTO);
    }
  }

  /**
   *
   * @param comprovacaoBenfDTO
   */
  prepareSaveComprovacao(comprovacaoBenfDTO: ComprovacaoBenf) {

    //console.log('comprovacaoBenfDTO: ', comprovacaoBenfDTO);

    if( !this.beneficioAtivoDependente.possuiTransporte ){
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoTransp = 'R';
      this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoTransp = 'N';

    }

    if( !this.beneficioAtivoDependente.possuiEducacao ){
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao = 'R';
      this.viewComprovacaoAnexo.comprovacaoBenef.renovSituacaoDocumentoInstituicao = 'N';
    }

    if (!this.mostrarOutroGenitorRenov) {
        if ( comprovacaoBenfDTO.renovDeclaracaoEmpregadorExiste === 'S' ||
             comprovacaoBenfDTO.renovDeclaracaoEmpregadorExiste === 'O' ||
             this.renovValorOutroGenitor > 0 ) {
            // Informar que estes dados serão descartados
             console.log ("Dados de Outro Genitor descartados.");
        }
        // descartar os dados
        comprovacaoBenfDTO.renovDeclaracaoEmpregadorExiste = null;
        comprovacaoBenfDTO.outrosDados.renovacao.valorReembolso = null;
        this.renovValorOutroGenitor = null;
    } else {
        comprovacaoBenfDTO.outrosDados.renovacao.valorReembolso = this.renovValorOutroGenitor;
    }

    // if (comprovacaoBenfDTO.isPendente === 'N') {
    //   this.confirmationService.confirm({
    //     message: 'Este dependente não está com pendência. Tem certeza que deseja adicionar a documentação?',
    //     accept: () => {
    //
    //     }
    //   });
    // }

    if (!comprovacaoBenfDTO.outrosDados) {
      comprovacaoBenfDTO.outrosDados = new OutrosDados();
    }
    // console.log("prepareSaveComprovacao:this.statusOutroGenitor:",this.statusOutroGenitor);

    comprovacaoBenfDTO.tipoBeneficio = this.comprovacaoBenfView.tipoBeneficiario;

    comprovacaoBenfDTO.outrosDados.renovacao.valorReembolso = this.renovValorOutroGenitor;


    // console.log('comprovacaoBenfDTO', comprovacaoBenfDTO);
    comprovacaoBenfDTO.comprovMatriculaConjugueExiste = this.statusOutroGenitor;
    // comprovacaoBenfDTO.outrosDados.comprovacao.statusOutroGenitor = this.statusOutroGenitor;

    comprovacaoBenfDTO.totalDocAnexadoInstituicao = this.anexoDocInstituicao != null ? this.anexoDocInstituicao.filesToUpload.length : 0;
    comprovacaoBenfDTO.totalDocAnexadoTransporte = this.anexosTransporte != null ? this.anexosTransporte.filesToUpload.length : 0;
    comprovacaoBenfDTO.totalDocAnexadoDeclaracaoEmpregador = this.anexosDeclaracaoEmpregador != null ? this.anexosDeclaracaoEmpregador.filesToUpload.length : 0;
    // comprovacaoBenfDTO.totalDocAnexadoConclusaoCurso = 0;
    comprovacaoBenfDTO.selectedIdsExclusao = this.buildSelectedIdsExclusao();
    // console.log("prepareSaveComprovacao:comprovacaoBenfDTO:",comprovacaoBenfDTO);
    comprovacaoBenfDTO.outrosDadosJSON = JSON.stringify(comprovacaoBenfDTO.outrosDados);
    this.saveComprovacaoAnexo(comprovacaoBenfDTO);
  }

  saveComprovacaoAnexo(comprovacaoBenfDTO: ComprovacaoBenf) {
    // console.log('saveComprovacaoAnexo:comprovacaoBenfDTO: ', comprovacaoBenfDTO);
    this.comprovacaoAuxilioEducacaoService.saveComprovacaoAnexo(comprovacaoBenfDTO, this.buildFiles(), this.renovacao).subscribe(
      result => {
        if (result.errorCode != null) {
          this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
          // this.toastService.showError(result.message);
        } else {
          this.messageService.add({
            key: 'tc',
            sticky: true,
            severity: 'success',
            summary: 'SUCESSO',
            detail: 'Seus dados foram salvos com sucesso! Não se esqueça de clicar em “Enviar Documentos”, no final da tela, para concluir o procedimento.'
          });
          // this.toastService.showSuccess('Seus dados foram salvos com sucesso');
          this.comprovacaoBenfView.idComprovacaoBenef = result;
          // console.log('idComprovacaoBenef: ', this.comprovacaoBenfView.idComprovacaoBenef);
          this.buildComprovacaoAnexo();
        }
      }
    );
  }


  private buildFiles(): File[] {
    const files: File[] = [];
    if (this.anexoDocInstituicao != null) {
      for (const fileObject of this.anexoDocInstituicao.filesToUpload) {
        files.push(fileObject);
      }
    }
    if (this.anexosTransporte != null) {
      for (const file of this.anexosTransporte.filesToUpload) {
        files.push(file);
      }
    }
    if (this.anexosDeclaracaoEmpregador != null) {
      for (const file of this.anexosDeclaracaoEmpregador.filesToUpload) {
        files.push(file);
      }
    }
    return files;
  }

  private buildSelectedIdsExclusao(): number[] {
    // console.log('listagemAnexoTransporte: ', this.listagemAnexoTransporte);
    const selectedIdsExclusao: number[] = [];
    if (this.listagemAnexoInstituicao != null && this.listagemAnexoInstituicao.selectedIdsExclusao != null) {
      for (const idExclusao of this.listagemAnexoInstituicao.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    if (this.listagemAnexoTransporte != null && this.listagemAnexoTransporte.selectedIdsExclusao != null) {
      for (const idExclusao of this.listagemAnexoTransporte.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    if (this.listagemAnexoDeclServidor != null && this.listagemAnexoDeclServidor.selectedIdsExclusao != null) {
      for (const idExclusao of this.listagemAnexoDeclServidor.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    return selectedIdsExclusao;
  }

}
