import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ToastService } from '@mpng/home';
import { AnexarComprovDepComponent } from '../anexar-comprov-dep/anexar-comprov-dep.component';
import { Pendencias } from '../../model/';

@Component({
  selector: 'app-benef-denpendente',
  templateUrl: './benef-denpendente.component.html',
  styleUrls: ['./benef-denpendente.component.css']
})
export class BenefDenpendenteComponent implements OnInit {


  @Input() listViewBenef: any [];

  private idComprovacaoWeb: number;


  @ViewChild('anexarComprovDep', {static: false}) anexarComprovDep: AnexarComprovDepComponent;

  @ViewChild('renovacao', {static: false}) renovacao: AnexarComprovDepComponent;


  // Dialog Pendência
  displayPendende: boolean = false;
  displayPendendeAnaliseAviso: boolean = false;

  // corPendente: string [] = [];

  listaDePendentes: string [] = [];

  isReady : boolean = false;


  constructor(private toastService: ToastService) {
  }

  ngOnInit() {

    // console.log('listViewBenefDep: ', this.listViewBenef);


    // console.log('idStatusComprovante: ', this.listViewBenef[0].comprovacaoBenefViewDTO.idStatusComprovante);

    if (Number(this.listViewBenef[0].comprovacaoBenefViewDTO.idStatusComprovante) === 2) {
      // console.log('Aqui: ');
    }

    // idStatusComprovante
    // this.mudaCorPendente(this.listViewBenef);

    // this.testaPendencia(this.listViewBenef);

    if (this.listViewBenef != null && this.listViewBenef.length > 0) {
      this.idComprovacaoWeb = this.listViewBenef[0].comprovacaoBenefViewDTO.idComprovacaoWeb;
    }

    this.isReady = true;
    this.temPendente();
    if(this.displayPendendeAnaliseAviso){
      //this.toastService.showWarningModal('Suas pendências estão em anélise pelo operador da GPBM. Não é possível fazer alterações na comprovação ou anexar arquivos até que o operador crie uma nova pendência.', 'Aviso');
    }

  }

  temPendente(){
    var ret : boolean = false;
    this.listViewBenef.forEach(element => {
      if(!this.displayPendende){
        this.displayPendende = (element.comprovacaoBenefViewDTO.idStatusComprovante == 3 || element.comprovacaoBenefViewDTO.idStatusComprovante == '3');        
      }
      if(!this.displayPendendeAnaliseAviso){
        this.displayPendendeAnaliseAviso = (element.comprovacaoBenefViewDTO.idStatusComprovante == 4 || element.comprovacaoBenefViewDTO.idStatusComprovante == '4');        
      }    
        
    });  
    
  }

  descricaoPendente(){
    return "    Pendente";
    /*+(tiposPendenciaComprov.instEnsino ? "Comprovação Instituição de Ensino" : "")
    +(tiposPendenciaComprov.instTranspote ? "Comprovação Transporte" : "")
    +(tiposPendenciaComprov.instOutroGenitor ? "Comprovação Outro Genitor" : "")
    
    +(tiposPendenciaRenov.instEnsino ? "Renovação Instituição de Ensino" : "")
    +(tiposPendenciaRenov.instTranspote ? "Renovação Transporte" : "")
    +(tiposPendenciaRenov.instOutroGenitor ? "Renovação Outro Genitor" : "")
    
    ;*/
  }

  // testaPendencia(viewBenef: any []) {
  //   for (const benef of viewBenef) {
  //     if (benef.comprovacaoBenefViewDTO.pendente === 'S') {
  //       this.listaDePendentes.push('  - Está com Pendência');
  //     }
  //     else {
  //       this.listaDePendentes.push('');
  //     }
  //   }
  //   console.log('listaDePendentes: ', this.listaDePendentes);
  // }

  // mudaCorPendente(viewBenef: any []): string[] {
  //
  //   for (const benef of viewBenef) {
  //     if (benef.comprovacaoBenefViewDTO.pendente === 'S') {
  //       this.corPendente.push('orange');
  //     }
  //     else {
  //       this.corPendente.push('blue');
  //     }
  //   }
  //   console.log('cor: ', this.corPendente);
  //   return this.corPendente;
  // }

  showDialog() {
    this.displayPendende = this.displayPendende === false;
  }

}
