import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { MessageService } from 'primeng/api';
import { LoggedUserService } from '@mpng/home';

import { AnexarArquivoComponent } from '../../anexar-arquivo/anexar-arquivo.component';

import { ComprovacaoBenf } from '../../model';
import { ComprovacaoAuxilioEducacaoService } from '../services/comprovacao-auxilio-educacao.service';

@Component({
  selector: 'app-lista-anexo',
  templateUrl: './lista-anexo.component.html',
  styleUrls: ['./lista-anexo.component.css']
})
export class ListaAnexoComponent implements OnInit {

  private anexo: any;
  private title: string;
  private displayPdf: boolean = false;
  private mostraAnexarArquivo: boolean = false;

  @Input() idTipoAnexoPrm: number;
  @Input() listAnexo: any[];
  selectedIdsExclusao: number[];
  selectedIdsAnalisado: number[];
  selectedIdsDownload: number[];

  @Input() editExclusao: boolean = false;
  private existeAlteracao: boolean = false;

  // @Input() dataComprovacao: Date;
  @Input() comprovacaoBenef: ComprovacaoBenf;
  private status: number;

  private eUsuario: boolean;
  
  @ViewChild('anexoDocOperador', {static: false}) anexoDocOperador: AnexarArquivoComponent;
  
  private estaSalvandoListaAnexo: boolean = false;

  /**
   *
   * @param comprovacaoAuxilioEducacaoService
   * @param messageService
   */
  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private messageService: MessageService,
    private loggedUserService: LoggedUserService
  ) {
  }

  ngOnInit() {
    this.eUsuario = !(this.loggedUserService.getLoggedUser().activeRole === 'Gestor Auxílio Educação' || this.loggedUserService.getLoggedUser().activeRole === 'Operador');
    console.log(this.idTipoAnexoPrm);
    //console.log('listAnexo: ', this.listAnexo);
    //console.log('comprovacaoBenef: ', this.comprovacaoBenef);
    // console.log('anexo: ', this.anexo);
    // console.log('eUsuario: ', this.eUsuario);
    //console.log('activeRole: ', '|' + this.loggedUserService.getLoggedUser().activeRole + '|');
    // console.log('dataComprovacao: ', this.dataComprovacao);
  }

  viewAnexoByID(id: number) {
    this.comprovacaoAuxilioEducacaoService.viewAnexoByID(id).subscribe(
      result => {
        // console.log('anexo: ', result);
        this.anexo = result.conteudo;
        this.displayPdf = true;
        this.title = result.nome;
        // console.log('anexo: ', this.anexo);
      }
    );
  }


  isPodeExcluir(): boolean {
    // console.log('dateAnexo', dateAnexo);
    // console.log('dataComprovacao', this.dataComprovacao);

    this.status = this.comprovacaoBenef.comprovacaoWeb.statusComprovante.id;
    // console.log('status: ', this.status);

    if (this.status === 1) {
      return true;
    } else {
      // console.log('entrou aqui nao');
      return false;
    }
  }


  updateExisteAlteracao() {
    this.existeAlteracao = true;
  }

  existeDownload(): boolean {
    return this.selectedIdsDownload != null && this.selectedIdsDownload.length > 0;
  }

  saveSituacaoAnexo() {
    for (const anexo of this.listAnexo) {
      if (anexo.inAnalisadoBoolean === true) {
        anexo.inAnalisado = 'S';
      } else {
        anexo.inAnalisado = 'N';
      }
    }
    this.comprovacaoAuxilioEducacaoService.saveSituacaoAnexo(this.listAnexo).subscribe(
      result => {

        // tslint:disable-next-line:no-non-null-assertion
        if (result!.errorCode !== undefined) {
          this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});

        } else {
          this.messageService.add({key: 'tc', sticky: true, severity: 'success', summary: 'SUCESSO', detail: 'Dados salvos com sucesso'});
          this.existeAlteracao = false;
        }
      }
    );

  }


  downloadComprovacaoAnexo() {
    for (const idAnexoDownload of this.selectedIdsDownload) {
      this.comprovacaoAuxilioEducacaoService.downloadComprovacaoAnexo(idAnexoDownload).subscribe(
        result => {
          const link = document.createElement('a');
          link.setAttribute('type', 'hidden');
          link.download = result.headers.get('fileName');
          link.href = window.URL.createObjectURL(result.body);
          document.body.appendChild(link);
          link.click();
        }
      );
    }
  }

  mostraTelaAnexarArquivo() {
      this.mostraAnexarArquivo = true;
  }

  anexaArquivo() {
      //console.log("Anexou um arquivo...");
  }
  
  salvaListaArquivos() {
      
      if (this.anexoDocOperador != null && this.anexoDocOperador.filesToUpload.length > 0) {
          
        this.estaSalvandoListaAnexo = true;
        
        const arquivos: File[] = [];
        for (const arquivo of this.anexoDocOperador.filesToUpload) {
          arquivos.push(arquivo);
        }
        
        const idBeneficiario: number = this.comprovacaoBenef.id;
        const idTipoAnexo: number = this.listAnexo != null && this.listAnexo.length > 0 
        && this.listAnexo[0].tipoComprovacaoAnexo != null ? this.listAnexo[0].tipoComprovacaoAnexo.id : this.idTipoAnexoPrm; 
        
        this.comprovacaoAuxilioEducacaoService.saveListaAnexoOperador(idBeneficiario, idTipoAnexo, arquivos).subscribe(
          result => {
            if (result!.errorCode !== undefined) {
              this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
              this.estaSalvandoListaAnexo = false;

            } else {
            
              this.comprovacaoAuxilioEducacaoService.viewAnexoByBenefAndTipo(idBeneficiario, idTipoAnexo).subscribe (
                result => {
                  if (result!.errorCode !== undefined) {
                    this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', 
                         detail: "Dados salvos, mas houve um erro ao recarregar a lista de arquivos: " + 
                         result.message + " => Por favor recarregue os dados do membro."});

                  } else {
                    this.listAnexo = result;
                    this.messageService.add({key: 'tc', sticky: true, severity: 'success', summary: 'SUCESSO', detail: 'Dados salvos com sucesso'});
                  }
                  this.estaSalvandoListaAnexo = false;
                  this.anexoDocOperador.filesToUpload = [];
                  this.mostraAnexarArquivo = false;
                }
              )    
            }
          }
        );

      } else {
        this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', 
             detail: 'Por favor adicione um arquivo a lista para salvar.'});
      }
  }
  
  verificaSePodeExcluirOperador(matranexo: string) : boolean {
      var matrBeneficiario: string = this.comprovacaoBenef.cdMatricula;      
      if (matranexo === matrBeneficiario) {
            return false;
      } 
      return true;
  }

  podeExcluirAnexoOperador(anexo: any): boolean{
    var ret : boolean =false;
    if(!this.eUsuario){
      if(anexo.matricula != this.comprovacaoBenef.cdMatricula){ 
        ret = true;
      }
    } 
    return ret;
  }
  
 deletaAnexoOperador(id: number, matranexo: string) {
     if (this.verificaSePodeExcluirOperador(matranexo)) {
      var idBeneficiario: number = this.comprovacaoBenef.id;
      const idTipoAnexo: number = this.listAnexo != null && this.listAnexo.length > 0 
      && this.listAnexo[0].tipoComprovacaoAnexo != null ? this.listAnexo[0].tipoComprovacaoAnexo.id : this.idTipoAnexoPrm; 
      this.comprovacaoAuxilioEducacaoService.deletaAnexoOperador(id).subscribe (
            result => {
              if (result!.errorCode !== undefined) {
                this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
              } else {            
                this.comprovacaoAuxilioEducacaoService.viewAnexoByBenefAndTipo(idBeneficiario, idTipoAnexo).subscribe (
                  result => {
                    if (result!.errorCode !== undefined) {
                      this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', 
                          detail: "Dados salvos, mas houve um erro ao recarregar a lista de arquivos: " + 
                          result.message + " => Por favor recarregue os dados do membro."});

                    } else {
                      this.listAnexo = result;
                      this.messageService.add({key: 'tc', sticky: true, severity: 'success', summary: 'SUCESSO', detail: 'Dados salvos com sucesso'});
                    }
                  }
                );
              }
            }
      );
  } else {
      this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', 
      detail: 'Este arquivo não pode ser excluído.'});
  }
}
}
