
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { ConfirmationService, MessageService } from 'primeng/api';

import { ComprovacaoBenfView } from '../../model/comprovacao-benefview';
import { AnexarArquivoComponent } from '../../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoBenf, OutrosDados, Pendencias, StatusComprovacaoENUM } from '../../model';
import { ListaAnexoComponent } from '../../shared/lista-anexo/lista-anexo.component';

@Component({
  selector: 'app-anexar-comprov-prop',
  templateUrl: './anexar-comprov-prop.component.html',
  styleUrls: ['./anexar-comprov-prop.component.css']
})

/*TODO - PENDÊNCIA - FALTA VERIFICAR A EXIBIÇÃO DO NOME DO CURSO E INST ENSINO PARA O USUÁRIO*/

export class AnexarComprovPropComponent implements OnInit {

  @Input() comprovacaoBenfView: ComprovacaoBenfView;
  @Input() renovacao: boolean;
  @Input() listViewBenef: any;
  @Input() codigoCurso: Number;




  private dialogId: string;
  viewComprovacaoAnexo: any;

  /*Verifica se o usuário interagiu com os componentes na tela*/
  private flagAlteracao: boolean = false;


  private comprovacaoBenfDTO: ComprovacaoBenf;
  private isReadyBuildComprovacaoAnexo: boolean = false;


  private isReadylistViewBenef: boolean = false;

  private limpaInputBenefProp: boolean = false;


  private disableEnviar: boolean = false;

  /*Armazenar dados da instituição de ensino no caso de benefício próprio*/

  /*Todo - Caso seja decidido usar o dropdown para exibição desses dados*/
  /*private instEnsino: SelectItem[];
  private selectedInstEnsino: string;
  private nomeCurso: SelectItem[];
  private selectedNomeCurso: string;*/

  private nomeCurso: string;
  private instEnsino: string;
  private dataConclusao: Date;
  private dataConclusaoCurso: Date;
  private outrosDadosJson: OutrosDados;

  private dataHoje: Date = new Date();
  private dataLimite: Date = new Date(2020, 12, 31);



  /*Todo - caso seja decidido permitir a renovação*/
  /*private nomeCursoRenov: string;
  private instEnsinoRenov: string;*/

  /*As variáveis abaixo serão utilizadas no método getStatusComprovacao()*/
  private statusComprovacao: number;
  private statusComprovacaoENUM : StatusComprovacaoENUM;
  private testaStatusComp: boolean = false;
  private isPendente: string;

  private enviar: boolean = false;


  tiposPendenciaComprov: Pendencias = new Pendencias();
  comprovIcon: string [] = [];
  comprovTip: string [] = [];

  anexarInstEnsino: boolean = false;
  anexarConclusaoCurso: boolean = false;



  @ViewChild('anexoDocInstituicao', {static: false}) anexoDocInstituicao: AnexarArquivoComponent;
  @ViewChild('anexoDocConclusaoCurso', {static: false}) anexoDocConclusaoCurso: AnexarArquivoComponent;
  @ViewChild('listagemAnexoInstituicao', {static: false}) listagemAnexoInstituicao: ListaAnexoComponent;
  @ViewChild('listagemAnexoConclusaoCurso', {static: false}) listagemAnexoConclusaoCurso: ListaAnexoComponent;


  /**
   *
   // * @param dialog
   * @param comprovacaoAuxilioEducacaoService
   * @param messageService
   * @param confirmationService
   * @param router
   */

  constructor(
    // private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private router: Router
  ) {


  }

  ngOnInit() {
    // this.dialogId = this.renovacao ? 'dialogRenovacao' + this.index : 'dialogComprovacao' + this.index;

    // console.log('listViewBenef: ', this.listViewBenef);

    // console.log('renovacao: ', this.renovacao);
    this.buildComprovacaoAnexo();

    // console.log('comprovacaoBenfView: ', this.comprovacaoBenfView);
    // console.log('viewComprovacaoAnexo: ', this.viewComprovacaoAnexo);

  }


  public buildComprovacaoAnexo() {
    this.flagAlteracao = false;

    this.comprovacaoAuxilioEducacaoService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView, this.renovacao).subscribe(
      result => {
        this.viewComprovacaoAnexo = result;
        this.comprovacaoBenfDTO = this.viewComprovacaoAnexo.comprovacaoBenef;

        /*TODO - local onde a data estimada de conclusão do curso é inserida*/
        // console.log('viewComprovacaoAnexo: ', this.viewComprovacaoAnexo);

        /*Preenchendo os dados do curso*/
        if (result.comprovacaoBenef.outrosDadosJSON !== null) {
          const outrosDados: OutrosDados = JSON.parse(result.comprovacaoBenef.outrosDadosJSON);
          // console.log('outrosDadosJson: ', outrosDados);
          if (outrosDados.comprovacao.cursos.length > 0) {
            this.nomeCurso = outrosDados.comprovacao.cursos[0].nome;
            this.instEnsino = outrosDados.comprovacao.cursos[0].instituicaoEnsino;
            this.dataConclusaoCurso = new Date(outrosDados.comprovacao.cursos[0].dataConclusao);
            this.dataLimite = this.dataConclusaoCurso;
          }
        }
        // console.log('comprovSituacaoConclusaoCurso: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso);
        if (this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao === 'A') {
          this.anexarInstEnsino = true;
        }
        else {
          this.anexarInstEnsino = false;
        }

        if (this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso === 'S') {
          this.anexarConclusaoCurso = true;
        }
        else {
          this.anexarConclusaoCurso = false;
        }
        // console.log('anexoConclusaoCurso: ', this.anexoConclusaoCurso);



        /*Todo - caso seja decidido permitir a renovação*/
        /*if (this.comprovacaoBenfDTO.outrosDados && this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos && this.renovacao === true) {
          this.nomeCursoRenov = this.comprovacaoBenfDTO.outrosDados.renovacao.cursos[0].nome;
          this.instEnsinoRenov = this.comprovacaoBenfDTO.outrosDados.renovacao.cursos[0].instituicaoEnsino;
        }*/


        // Todo ------> console.log('listAnextoInstituicaoAntes: ', this.viewComprovacaoAnexo.listAnextoInstituicao);
        // Todo ------> console.log('anexoDocInstituicaoAntes: ', this.anexoDocInstituicao);
        if (this.anexoDocInstituicao != null) {
          this.anexoDocInstituicao.filesToUpload = [];
        }

        // console.log('anexoDocConclusaoCurso: ', this.anexoDocConclusaoCurso);

        if (this.anexoDocConclusaoCurso != null) {
          this.anexoDocConclusaoCurso.filesToUpload = [];
        }

        this.isReadyBuildComprovacaoAnexo = true;


        this.tiposPendenciaComprov = this.viewComprovacaoAnexo.comprovacaoBenef.outrosDados.comprovacao.pendencias;
        this.testaTiposPendencia(this.tiposPendenciaComprov);


        this.getStatusComprovacao();

        // console.log('dataHoje: ', this.dataHoje);
        // console.log('dataConclusaoCurso: ', this.dataConclusaoCurso);
      }
    );

  }

  testaTiposPendencia(tiposPendenciaComprov: Pendencias) {
    this.statusComprovacao = this.comprovacaoBenfDTO.comprovacaoWeb.statusComprovante.id;
    // Comprovação
    if (tiposPendenciaComprov.instEnsino === true) {
      // this.comprovIcon.push('pi pi-times');
      this.comprovIcon.push('pi pi-exclamation-triangle');
      this.comprovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.comprovIcon.push('pi pi-check');
      this.comprovTip.push('');
    }
    else {
      this.comprovIcon.push('');
      this.comprovTip.push('');
    }
    if (tiposPendenciaComprov.instConclusaoCurso === true) {
      // this.comprovIcon.push('pi pi-times');
      this.comprovIcon.push('pi pi-exclamation-triangle');
      this.comprovTip.push('Existe Pendência nessa Documentação');
    }
    else if (this.statusComprovacao > 2){
      this.comprovIcon.push('pi pi-check');
      this.comprovTip.push('');
    }
    else {
      this.comprovIcon.push('');
      this.comprovTip.push('');
    }
    // console.log('comprovIcon', this.comprovIcon);
    // console.log('comprovTip', this.comprovTip);

  }



  /*As variáveis abaixo serão utilizadas para testar o status da comprovação e bloquear os componentes evitando que o usuário realize alterações fora do período permitido*/
  public getStatusComprovacao() {
    // this.statusComprovacao = this.viewComprovacaoAnexo.comprovacaoBenef.comprovacaoWeb.statusComprovante.id;
    this.isPendente = this.viewComprovacaoAnexo.comprovacaoBenef.isPendente;

    if (this.statusComprovacao.valueOf() === StatusComprovacaoENUM.TODOS.valueOf() || this.statusComprovacao.valueOf() === StatusComprovacaoENUM.DOCUMENTOS_NAO_ENVIADOS.valueOf()) {

      this.testaStatusComp = true;
      // console.log('flag alteraçao: ', this.flagAlteracao);
    } else if (this.statusComprovacao.valueOf() === StatusComprovacaoENUM.COM_PENDENCIA.valueOf() && this.isPendente === 'S') {
      this.testaStatusComp = true;

    } else {
      this.testaStatusComp = false;

      // console.log('status 5');
      // console.log('flag alteraçao: ', this.flagAlteracao);
    }
    // console.log('statusComprovacao: ', this.statusComprovacao);
    // console.log('testaStatusComp: ', this.testaStatusComp);

    // console.log('comprovSituacaoDocumentoInstituicao: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao);
    // console.log('comprovacaoBenfDTO: ', this.comprovacaoBenfDTO);
    if (this.testaStatusComp && this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao) {
      this.enviar = true;
    }
    else if (this.testaStatusComp && this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso) {
      this.enviar = true;
    }
    else {
      this.enviar = false;
    }
  }


  updateFlagAlteracao(): void {

    if (this.anexarInstEnsino === true) {
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao = 'A';
    }
    else {
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao = 'R';
    }

    if (this.anexarConclusaoCurso === true) {
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso = 'S';
    }
    else {
      this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso = 'N';
    }

    // console.log('comprovSituacaoConclusaoCurso: ', this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoConclusaoCurso);

    if (this.testaStatusComp) {
      this.flagAlteracao = true;
      // console.log('flag alteraçao: ', this.flagAlteracao);
      if (this.viewComprovacaoAnexo.comprovacaoBenef.comprovSituacaoDocumentoInstituicao === 'R') {
        this.limpaInputBenefProp = false;
        this.instEnsino = '';
        this.nomeCurso = '';
        this.dataConclusaoCurso = undefined;
      }
      else {
        this.limpaInputBenefProp = true;
        // console.log('cursos: ', this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos);
        if (this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos.length > 0) {
          this.instEnsino = this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].instituicaoEnsino;
          this.nomeCurso = this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].nome;
          this.dataConclusaoCurso = new Date(this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].dataConclusao);
          this.dataLimite = this.dataConclusaoCurso;
        }
      }

    }
    else {
      this.flagAlteracao = false;
      // console.log('flag alteraçao: ', this.flagAlteracao);
    }

  }

  public blurDataConclusao(componente){
    this.comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].dataConclusao = new Date(this.dataConclusaoCurso);

  }

  public cancel() {
    this.buildComprovacaoAnexo();
  }

  public existeAlteracao(): boolean {
    return this.flagAlteracao;
  }

  private isConclusaoValida(){
    let ret = ((this.dataHoje >= this.dataConclusaoCurso ) && (this.anexoDocConclusaoCurso != null && this.anexoDocConclusaoCurso.filesToUpload.length > 0 )) ||
            (this.dataHoje < this.dataConclusaoCurso ) ;
    
    if(!ret){
      ret = (this.viewComprovacaoAnexo.listAnexoConclusaoCurso != null && this.viewComprovacaoAnexo.listAnexoConclusaoCurso.length > 0);
    }
    return ret;
  }
  /**
   *
   * @param comprovacaoBenfDTO
   */

   alterouData(this){
    // alert('asdasd');
   }

  prepareSaveComprovacao(comprovacaoBenfDTO: ComprovacaoBenf) {

    // console.log('comprovacaoBenfDTO: ', comprovacaoBenfDTO);

    if (!comprovacaoBenfDTO.outrosDados) {
      comprovacaoBenfDTO.outrosDados = new OutrosDados();
    }

    if(this.dataConclusaoCurso == null ){
      this.messageService.add({
        key: 'tc',
        sticky: true,
        severity: 'warn',
        summary: 'ERROR',
        detail: 'Data estimada para conclusão do curso não informada.'
      });
      return;
    }

    if (!this.isConclusaoValida()) {
      this.messageService.add({
        key: 'tc',
        sticky: true,
        severity: 'warn',
        summary: 'ERROR',
        detail: 'O comprovante de conclusão tem que ser anexado.'
      });
      return;
    }
    // console.log('SAVE-tipoAuxilio:', this.comprovacaoBenfDTO.comprovacaoWeb.periodoReferencia.tipoBeneficio.tipoAuxilio);
    // console.log('tipoBeneficiario: ', this.comprovacaoBenfView.tipoBeneficiario);

    comprovacaoBenfDTO.tipoBeneficio = this.comprovacaoBenfView.tipoBeneficiario;

    /*if ( this.instEnsino && this.nomeCurso && !this.renovacao ) {*/
    /*if ( comprovacaoBenfDTO.outrosDados.comprovacao.cursos.length > 0 ) {
      if (this.nomeCurso) {
        comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].nome = this.nomeCurso;
      }
      else {
        alert('Por favor, preencha o nome do curso.');
      }
      if (this.instEnsino ) {
        comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].instituicaoEnsino = this.instEnsino;
      }
      else {
        alert('Por favor, preencha o nome da instituição de ensino.');
      }
      if (this.dataConclusaoCurso) {
        comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].dataConclusao = this.dataConclusaoCurso;
      }
      else {
        alert('Por favor, preencha a data de conclusão de curso.');
      }
    }*/


    if (comprovacaoBenfDTO.outrosDados.comprovacao.cursos.length > 0) {
      comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].nome = this.nomeCurso;
      comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].instituicaoEnsino = this.instEnsino;
      comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].dataConclusao = this.dataConclusaoCurso;
      comprovacaoBenfDTO.outrosDados.comprovacao.cursos[0].codigoCurso = this.codigoCurso;
    } else {
      comprovacaoBenfDTO.outrosDados.comprovacao.cursos.push({nome: this.nomeCurso, instituicaoEnsino: this.instEnsino, dataConclusao: this.dataConclusaoCurso, codigoCurso : this.codigoCurso});
    }
    /*}*/

    /*Todo - caso seja decidido permitir a renovação*/
    /*if (this.instEnsinoRenov && this.nomeCursoRenov && this.renovacao === true && comprovacaoBenfDTO.outrosDados.comprovacao.cursos.length > 0) {
      comprovacaoBenfDTO.outrosDados.renovacao.cursos[0].nome = this.nomeCursoRenov;
      comprovacaoBenfDTO.outrosDados.renovacao.cursos[0].instituicaoEnsino = this.instEnsinoRenov;
    }*/

    // comprovacaoBenfDTO.totalDocAnexadoInstituicao = this.anexoDocInstituicao != null ? this.anexoDocInstituicao.filesToUpload.length : 0;
    comprovacaoBenfDTO.totalDocAnexadoInstituicao = this.anexoDocInstituicao != null ? this.anexoDocInstituicao.filesToUpload.length : 0;
    comprovacaoBenfDTO.totalDocAnexadoConclusaoCurso = this.anexoDocConclusaoCurso != null ? this.anexoDocConclusaoCurso.filesToUpload.length : 0;

    // console.log('totalDocAnexadoInstituicao: ', comprovacaoBenfDTO.totalDocAnexadoInstituicao);

    // Todo ------> console.log('listAnextoInstituicaoSave: ', this.viewComprovacaoAnexo.listAnextoInstituicao);

    /*TODO - Verificar porque não está sendo recuperado o valor de filesToUpload*/
    /*NÃO Utilizar essa variavel para a conta de quantos arquivos foram anexados, pois pertence a outro componente*/
    // console.log('anexoDocInstituicaoDepois: ', this.anexoDocInstituicao);
    // console.log('filesToUploadDepois: ', this.anexoDocInstituicao.filesToUpload);

    comprovacaoBenfDTO.selectedIdsExclusao = this.buildSelectedIdsExclusao();
    this.saveComprovacaoAnexo(comprovacaoBenfDTO);
  }


  saveComprovacaoAnexo(comprovacaoBenfDTO: ComprovacaoBenf) {

    /*TODO - Creio que seja qui que os arquivos anexados são enviados "this.buildFiles()"*/

    this.comprovacaoAuxilioEducacaoService.saveComprovacaoAnexo(comprovacaoBenfDTO, this.buildFiles(), this.renovacao).subscribe(
      result => {
        if (result.errorCode != null) {
          this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
          // this.toastService.showError(result.message);
        } else {
          this.messageService.add({
            key: 'tc',
            sticky: true,
            severity: 'success',
            summary: 'SUCESSO',
            detail: 'Seus dados foram salvos com sucesso! Não se esqueça de clicar em “Enviar Documentos”, no final da tela, para concluir o procedimento.'
          });
          // this.toastService.showSuccess('Seus dados foram salvos com sucesso');
          this.comprovacaoBenfView.idComprovacaoBenef = result;
          // console.log('idComprovacaoBenefProp: ', this.comprovacaoBenfView.idComprovacaoBenef);
          this.buildComprovacaoAnexo();
        }
      }
    );
  }

  public getListViewBenef(): any[] {
    return this.listViewBenef;
    this.isReadylistViewBenef = true;
  }

  enviarComprovacaoBP() {
    this.messageService.clear();
    if (this.existeAlteracao()) {
      this.messageService.add({
        key: 'tc',
        sticky: true,
        severity: 'warn',
        summary: 'ERROR',
        detail: 'Existem alterações não salvas. Salve ou cancele antes de enviar a declaração.'
      });
      return;
    }
    

    const msg: string = ' Atenção! Após enviar a documentação, não será permitido anexar novos documentos ou modificar os documentos enviados. Deseja continuar?';
    this.confirmationService.confirm({
      key: 'dialogConfirmEnvio',
      message: msg,
      accept: () => {
        this.comprovacaoAuxilioEducacaoService.enviarComprovacaoBP().subscribe(
          result => {
            // tslint:disable-next-line:no-non-null-assertion
            if (result!.errorCode !== undefined) {
              /*Todo - Módulo de mensagem*/
              this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
            } else {

              // this.disableEnviar = true;

              this.messageService.add({
                key: 'tc',
                severity: 'success',
                sticky: true,
                summary: 'SUCESSO',
                detail: 'Documentos enviados com sucesso. Confirmação enviada para seu e-mail funcional.'
              });
              this.buildComprovacaoAnexo();
              // this.buildViewComprovacao();
              // this.buildViewlistBeneficiario();
            }
          }
        );

      }
    });
  }


  private buildFiles(): File[] {
    const files: File[] = [];
    if (this.anexoDocInstituicao != null) {
      for (const fileObject of this.anexoDocInstituicao.filesToUpload) {
        // console.log('anexoDocInstituicaoBuild: ', this.anexoDocInstituicao);
        // console.log('files: ', files);
        // console.log('fileObject: ', fileObject);
        files.push(fileObject);
      }
    }
    if (this.anexoDocConclusaoCurso != null) {
      for (const fileObject of this.anexoDocConclusaoCurso.filesToUpload) {
        // console.log('anexoDocInstituicaoBuild: ', this.anexoDocInstituicao);
        // console.log('files: ', files);
        // console.log('fileObject: ', fileObject);
        files.push(fileObject);
      }
    }
    // console.log('files: ', files);
    return files;
  }

  private buildSelectedIdsExclusao(): number[] {
    const selectedIdsExclusao: number[] = [];
    if (this.listagemAnexoInstituicao != null && this.listagemAnexoInstituicao.selectedIdsExclusao != null) {
      for (const idExclusao of this.listagemAnexoInstituicao.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    if (this.listagemAnexoConclusaoCurso != null && this.listagemAnexoConclusaoCurso.selectedIdsExclusao != null) {
      for (const idExclusao of this.listagemAnexoConclusaoCurso.selectedIdsExclusao) {
        selectedIdsExclusao.push(idExclusao);
      }
    }
    return selectedIdsExclusao;
  }

}
