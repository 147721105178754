import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ComprovacaoAuxilioEducacaoService } from '../../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoOcorrenciaDTO } from '../../../model/comprovacao-ocorrencia.dto';

@Component({
  selector: 'app-mantem-ocorrencia',
  templateUrl: './mantem-ocorrencia.component.html',
  styleUrls: ['./mantem-ocorrencia.component.css']
})
export class MantemOcorrenciaComponent implements OnInit {

  @Input() idComprovacaoWeb: number;

  @Input() tipo: string;

  @Input() matricula: string;
  
  private ocorrencia: ComprovacaoOcorrenciaDTO;

  //salvarOcorrencia: boolean = false;

  private txtOcorrencia: string = '';
  private desabilitarSalvar: boolean = true;

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
  ) { }

  ngOnInit() {
    //console.log ("id: ", this.idComprovacaoWeb);
    //console.log ("tipo: ", this.tipo);
    this.carregaOcorrencia();
  }

  carregaOcorrencia() {
    this.comprovacaoAuxilioEducacaoService.findOcorrencia(this.idComprovacaoWeb, this.tipo).subscribe(
    result => {
      //console.log('result find ocorrencia: ', result);
      this.inicializaOcorrencia(result);
      //console.log('result find ocorrencia: ', this.ocorrencia);
    }
    )
  } 

  salvarOcorrencia(){
    this.ocorrencia.idComprovacaoWeb = this.idComprovacaoWeb;
    this.ocorrencia.matricula = this.matricula;
    this.ocorrencia.tipo = this.tipo;
    this.ocorrencia.ocorrencia = this.txtOcorrencia;
    this.comprovacaoAuxilioEducacaoService.salvarOcorrencia(this.ocorrencia).subscribe(
      result => {
        //console.log('salvar ocorrencia: ', result);
        this.inicializaOcorrencia(result);
        this.desabilitarSalvar = true;
      }
    )
  }

  inicializaOcorrencia(ocorrencia: ComprovacaoOcorrenciaDTO){
    if (ocorrencia) {
      this.ocorrencia = ocorrencia;
      this.txtOcorrencia = this.ocorrencia.ocorrencia;
    }else{
      this.ocorrencia = new ComprovacaoOcorrenciaDTO;
      this.txtOcorrencia = "";
    }
  }

  alterarTxt(){
    this.desabilitarSalvar = false;
  }
 }               
