import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LoggedUserService } from '@mpng/home';

@Injectable({
    providedIn: 'root'
})
export class PingService {
    private pingUrl = '/api/user/ping';

    constructor(private http: HttpClient, private loggedUserService: LoggedUserService) {
        //console.log("Ping 0");
    }

    ping() {
        let delay = 120000;
        //console.log("Ping 1");

        this.http.get(this.pingUrl).subscribe(
            data => {
                //console.log("Ping 2:", data);
                setTimeout(() => this.ping(), delay);
            },
            err => {
                //console.log("Ping 3:", err);
                setTimeout(() => this.ping(), delay);
            }
        );

    }
}
