import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';

import { AnexarComprovPropComponent } from '../anexar-comprov-prop/anexar-comprov-prop.component';
import { ComprovacaoAuxilioEducacaoService } from '../../shared';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-benef-proprio',
  templateUrl: './benef-proprio.component.html',
  styleUrls: ['./benef-proprio.component.css'],
  providers: [DatePipe]
})
export class BenefProprioComponent implements OnInit {


  @Input() viewBenef: any;

  private idComprovacaoWeb: number;

  @ViewChild('anexarComprovProp', {static: false}) anexarComprovProp: AnexarComprovPropComponent;


  /*Todo - Ficou decidido que não haveria a possibilidade de renovação para o membro.*/
  temRenovacao: boolean = false;

  // Dialog Pendência
  displayPendende: boolean = false;
  isReady : boolean = false;


  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit() {
    // this.dataHoje = this.datePipe.transform(this.dataHoje, 'yyyy-MM-dd');
    // console.log('viewBenef: ', this.viewBenef);

    this.idComprovacaoWeb = this.viewBenef.comprovacaoBenefViewDTO.idComprovacaoWeb;
    alert(this.viewBenef.comprovacaoBenefViewDTO.pendente=='S');
    console.log('this.viewBenef.comprovacaoBenefViewDTO.pendente==S  ===> ' + this.viewBenef.comprovacaoBenefViewDTO.pendente=='S');
    this.displayPendende = this.viewBenef.comprovacaoBenefViewDTO.pendente=='S';  
    // console.log('idComprovacaoWeb: ', this.idComprovacaoWeb);
    this.isReady = true;
  }


  showDialog() {
    alert('showDialog');
    this.displayPendende = true;

  }

}
