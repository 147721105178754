import { Email, Pendencias } from './comprovacao-outros-dados';

export class TrataPendenciaModel {
  idDependente: number;
  pendenciasComprovacao: Pendencias = new Pendencias();
  pendencuasRenovacao: Pendencias = new Pendencias();
}

export class TrataPendenciaPostModel {
  email: Email;
  pendencias: TrataPendenciaModel[];
}
