import { Component, OnInit } from '@angular/core';

import { LoggedUserService, ToastService, MpSalutationPipe } from '@mpng/home';

@Component({
    selector: 'app-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.css'],
    providers: [MpSalutationPipe]
})
export class WelcomeComponent implements OnInit {

    constructor(
      private toast: ToastService,
      private auth: LoggedUserService,
      private salutation: MpSalutationPipe
    ) { }

    ngOnInit() {
        this.toast.showSuccess(this.salutation.transform('Olá, ' + this.auth.getLoggedUser().name));
        // this.toast.showInfo('User!' + this.auth.getLoggedUser().login);
    }

}
