import { Component, Input, OnInit } from '@angular/core';

import { ToastService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';


@Component({
  selector: 'app-exibe-recibo',
  templateUrl: './exibe-recibo.component.html',
  styleUrls: ['./exibe-recibo.component.css']
})
export class ExibeReciboComponent implements OnInit {

  private anexo: any;
  private displayPdf: boolean = false;
  @Input() idComprovacaoWeb: number;
  @Input() idStatusComprovacao: number;

  private existeRecibo: boolean = false;


  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    // console.log('idComprovacaoWeb: ', this.idComprovacaoWeb);
    // console.log('idStatusComprovacao: ', this.idStatusComprovacao);
  }

  visualizarReciboComprovacao() {
    this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(this.idComprovacaoWeb).subscribe(
      result => {
        // console.log('reciboComprovacaoAntes: ', result.reciboComprovacao);
        if (result.reciboComprovacao) {
          this.anexo = result.reciboComprovacao;
          this.displayPdf = true;
          this.existeRecibo = true;
          // console.log('visualizarReciboComprovacao', result);
        }
        else {
          this.existeRecibo = false;
          // console.log('ReciboCompNulo');
        }
      }
    );

  }


  visualizarEmailReciboComprovacao() {
    this.comprovacaoAuxilioEducacaoService.findComprovacaoWebById(this.idComprovacaoWeb).subscribe(
      result => {
        this.anexo = result.pdfMessageEmailComprovacao;
        this.displayPdf = true;
        // console.log('visualizarReciboComprovacao', result);
      }
    );

  }

}
