import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';


/*Material modules*/
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatRadioModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatTooltipModule
} from '@angular/material';


import { ToastModule } from 'primeng/toast';
import { FieldsetModule } from 'primeng/fieldset';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import {
  AccordionModule,
  AutoCompleteModule,
  CalendarModule,
  CheckboxModule,
  ConfirmationService,
  DropdownModule,
  EditorModule, FileUploadModule,
  InputMaskModule,
  InputTextModule,
  MessageModule, MessagesModule, OverlayPanelModule,
  PaginatorModule,
  PanelModule,
  RadioButtonModule,
  SharedModule,
  TabViewModule,
  TooltipModule
} from 'primeng/primeng';


import {
  FileUploadService,
  GenericAlertService,
  MpFileUploadModule,
  MpMessageWithUploadModule,
  MpPdfViewerModule,
  PipesModule,
  SpaModule
} from '@mpng/home';
import { LoggerModule, LogLevel } from '@mpng/logger';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DeviceDetectorModule } from 'ngx-device-detector';


import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { AppRoutingModule } from './app-routing.module';
import { ResourceBundleService } from './shared/services/resource-bundle.service';
import { ComprovacaoOperadorComponent } from './comprovacao-operador/comprovacao-operador.component';
import { ComprovacaoUsuarioModule } from './comprovacao-usuario/comprovacao-usuario.module';
import { AnexarArquivoModule } from './anexar-arquivo/anexar-arquivo.module';
import { ComprovacaoUsuarioComponent } from './comprovacao-usuario/comprovacao-usuario.component';
import { BenefProprioComponent } from './comprovacao-usuario/benef-proprio/benef-proprio.component';
import { BenefDenpendenteComponent } from './comprovacao-usuario/benef-denpendente/benef-denpendente.component';
import { InfoPendenciasComponent } from './shared/info-pendencias/info-pendencias.component';
import { ConsultaPendenciaComponent } from './comprovacao-operador/consulta-pendencia/consulta-pendencia.component';
import { AnexarComprovPropComponent } from './comprovacao-usuario/anexar-comprov-prop/anexar-comprov-prop.component';
import { AnexarComprovDepComponent } from './comprovacao-usuario/anexar-comprov-dep/anexar-comprov-dep.component';
import { ConsultaPendenciaPropComponent } from './comprovacao-operador/consulta-pendencia/consulta-pendencia-prop/consulta-pendencia-prop.component';
import { ConsultaPendenciaDepComponent } from './comprovacao-operador/consulta-pendencia/consulta-pendencia-dep/consulta-pendencia-dep.component';
import { ComprovacaoGestorComponent } from './comprovacao-gestor/comprovacao-gestor.component';
import { ListaAnexoComponent } from './shared/lista-anexo/lista-anexo.component';
import { GeraPendenciaComponent } from './comprovacao-operador/consulta-pendencia/gera-pendencia/gera-pendencia.component';
import { ExibeReciboComponent } from './comprovacao-usuario/exibe-recibo/exibe-recibo.component';
import { ComprovacaoAuxilioEducacaoService } from './shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoAuxilioEducacaoListPendenciaComponent } from './list-pendencia/comprovacao-auxilio-educacao-listpendencia.component';
import { ComprovacaoAuxilioEducacaoAvisoComponent } from './comprovacao-usuario/aux-educ-aviso/comprovacao-auxilio-educacao-aviso.component';
import { TrocarStatusComponent } from './comprovacao-operador/trocar-status/trocar-status.component';
import { ListaAnualComponent } from './comprovacao-operador/lista-anual/lista-anual.component';
import { MantemOcorrenciaComponent } from './comprovacao-operador/consulta-pendencia/mantem-ocorrencia/mantem-ocorrencia.component';



@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    ComprovacaoOperadorComponent,
    ComprovacaoUsuarioComponent,
    BenefProprioComponent,
    BenefDenpendenteComponent,
    InfoPendenciasComponent,
    ConsultaPendenciaComponent,
    AnexarComprovPropComponent,
    AnexarComprovDepComponent,
    ConsultaPendenciaPropComponent,
    ConsultaPendenciaDepComponent,
    ComprovacaoGestorComponent,
    ListaAnexoComponent,
    GeraPendenciaComponent,
    ExibeReciboComponent,
    ComprovacaoAuxilioEducacaoAvisoComponent,
    ComprovacaoAuxilioEducacaoListPendenciaComponent,
    TrocarStatusComponent,
    ListaAnualComponent,
    MantemOcorrenciaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SpaModule.forRoot(),
    PipesModule,
    HttpClientModule,
    ToastModule,
    FieldsetModule,
    ConfirmDialogModule,
    CardModule,
    AccordionModule,
    CheckboxModule,
    TabViewModule,
    DialogModule,
    RadioButtonModule,
    DropdownModule,
    CalendarModule,
    TableModule,
    PaginatorModule,
    LoggerModule.forRoot({level: LogLevel.All}),
    EditorModule,
    InputMaskModule,
    MpPdfViewerModule,
    TooltipModule,
    MessageModule,
    InfiniteScrollModule,
    CommonModule,
    PanelModule,
    InputTextModule,
    SharedModule,
    AutoCompleteModule,
    MpMessageWithUploadModule,
    FileUploadModule,
    MpFileUploadModule,
    MessagesModule,
    OverlayPanelModule,
    DeviceDetectorModule.forRoot(),

    ComprovacaoUsuarioModule,
    AnexarArquivoModule,

    /*Material modules*/
    MatIconModule,
    MatChipsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatDialogModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatListModule,
    MatStepperModule,
    MatCheckboxModule,
  ],

  providers: [
    ResourceBundleService,
    GenericAlertService,
    ComprovacaoAuxilioEducacaoService,
    ConfirmationService,
    FileUploadService
  ],

  entryComponents: [
    ComprovacaoAuxilioEducacaoAvisoComponent,
  ],

  exports: [
    ListaAnexoComponent,
    ComprovacaoAuxilioEducacaoAvisoComponent,
    ComprovacaoAuxilioEducacaoListPendenciaComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
