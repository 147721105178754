import { Component, OnInit } from '@angular/core';
import { ToastService } from '@mpng/home';

import { ComprovacaoAuxilioEducacaoService } from '../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoListaAnualDTO } from '../../model';

@Component({
  selector: 'app-lista-anual',
  templateUrl: './lista-anual.component.html',
  styleUrls: ['./lista-anual.component.css']
})
export class ListaAnualComponent implements OnInit {

  private ano: string;
  private comprovacaoListaAnual: ComprovacaoListaAnualDTO[];
  private totalRegisters: number;
  private page: number;
  private isReady: boolean = false;

  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
  }
   preencheGrid() {
    this.comprovacaoAuxilioEducacaoService.findComprovacaoListaAnualPaginator(this.ano, this.page).subscribe(
      result => {

        this.totalRegisters = result.totalRegisters;
        this.comprovacaoListaAnual = result.registers;
        if(this.comprovacaoListaAnual.length === 0){
          this.toastService.showInfo(('Nenhum registro encontrado.'));
          return false;
        }
        this.isReady = true;
      }
    );
  }

  pesquisar() {
    if(this.ano == null){
      this.toastService.showInfo(('Por favor informe o ano.'));
      return false;
    }
    this.page = 0;
    this.comprovacaoListaAnual = [];
    this.preencheGrid();
  }

  paginate(event) {
    this.page = event.page;
    this.preencheGrid();
  }

  downloadRelatorioComprovacaoByAno() {
    this.comprovacaoAuxilioEducacaoService.downloadRelatorioListaAnual(this.ano).subscribe(
      result => {
        const link = document.createElement('a');
        link.setAttribute('type', 'hidden');
        link.download = result.headers.get('fileName');
        link.href = window.URL.createObjectURL(result.body);
        document.body.appendChild(link);
        link.click();
      }
    );
  }

}
