import { Injectable } from '@angular/core';

import { messages } from '../../../assets/messageBundle.json';

@Injectable({
  providedIn: 'root'
})
export class ResourceBundleService {
  private messagesBundle: any;

  private messageMap: Map<string, string> = new Map();

  constructor() {
    this.initMessageMap();
    // console.log('retorno:', ret);
    // console.log('msg 1:', this.getMessage('msg.um'));
  }

  initMessageMap() {
    this.messagesBundle = messages;
        // @ts-ignore
    for (const msg: {key: string, value: string} of messages) {
          this.messageMap.set(msg.key, msg.value);
        }
  }

  public getMessage(key: string): string {
    return this.messageMap.get( key );
  }
}
