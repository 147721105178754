

export enum StatusComprovacaoENUM {
  TODOS = 0,
  DOCUMENTOS_NAO_ENVIADOS = 1,
  DOCUMENTOS_ENVIADOS = 2,
  COM_PENDENCIA = 3,
  PENDENCIA_EM_ANALISE = 4,
  RECIDO_COMPROVACAO_ENVIADO = 5
}
