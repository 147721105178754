import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewRef, ViewChild } from '@angular/core';

import { Logger, LoggerService } from '@mpng/logger';

import { ComprovacaoAuxilioEducacaoService } from '../../../shared/services/comprovacao-auxilio-educacao.service';
import { ComprovacaoBenfView } from '../../../model/comprovacao-benefview';
import { AnexarArquivoComponent } from '../../../anexar-arquivo/anexar-arquivo.component';
import { Genitor } from '../../../model/';

@Component({
  selector: 'app-consulta-pendencia-dep',
  templateUrl: './consulta-pendencia-dep.component.html',
  styleUrls: ['./consulta-pendencia-dep.component.css']
})

// @LoggerComponent()
export class ConsultaPendenciaDepComponent implements OnInit, OnDestroy {

  // @Input() viewBenef: any [];
  private log: Logger;

  @Input() comprovacaoBenfView: ComprovacaoBenfView;

  // Todo - verificar como resolver a questão da variável renovação
  private renovacao: boolean;

  // private viewComprovacaoAnexo: any;

  private viewComprovacaoAnexoCompr: any;

  private viewComprovacaoAnexoRenov: any;


  private comprovacaoOutroGenitorTransporte: Genitor;
  private comprovacaoOutroGenitorEscola: Genitor;
  private renovacaoOutroGenitorTransporte: Genitor;
  private renovacaoOutroGenitorEscola: Genitor;

  private msgComprovacaoEscolaOutroGenitor: string;
  private msgComprovacaoTransporteOutroGenitor: string;
  private msgRenovacaoEscolaOutroGenitor: string;
  private msgRenovacaoTransporteOutroGenitor: string;

  private valorReembolso: any = 0;

  @Input() corOpcaoSim: string;
  // @Input() corOpcaoNao: string;
  // @Input() corOpcaoOutro: string;

  private isReady1: boolean = false;
  private isReady2: boolean = false;

  private idStatusComprovante: number;

  @ViewChild('anexoDocInstituicao', {static: false}) anexoDocInstituicao: AnexarArquivoComponent;
  @ViewChild('anexosTransporte', {static: false}) anexosTransporte: AnexarArquivoComponent;
  @ViewChild('anexosDeclaracaoEmpregador', {static: false}) anexosDeclaracaoEmpregador: AnexarArquivoComponent;


  constructor(
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private cd: ChangeDetectorRef,
    private loggerService: LoggerService
  ) {
    this.log = loggerService.create(this.constructor.name);
  }

  ngOnInit() {
    this.idStatusComprovante = Number(this.comprovacaoBenfView.idStatusComprovante);

    // Teste Novo Status "comprovação fora do caem"
    // this.idStatusComprovante = 6;

    this.buildComprovacaoAnexo();

    // this.log.info('Logger: ');

    // console.log('comprovacaoBenfView: ', this.comprovacaoBenfView);
    // console.log('idStatusComprovante: ', this.idStatusComprovante);

  }

  public buildComprovacaoAnexo() {
    this.isReady1 = false;
    this.isReady2 = false;
    this.comprovacaoAuxilioEducacaoService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView, false).subscribe(
      result => {

        // console.log('result: ', result);
        this.viewComprovacaoAnexoCompr = result;
        if (this.anexoDocInstituicao != null) {
          this.anexoDocInstituicao.filesToUpload = [];
          // console.log('anexoDocInstituicao: ', this.anexoDocInstituicao.filesToUpload);
        }
        if (this.anexoDocInstituicao != null) {
          this.anexosTransporte.filesToUpload = [];
        }
        if (this.anexosDeclaracaoEmpregador != null) {
          this.anexosDeclaracaoEmpregador.filesToUpload = [];
        }
        this.isReady1 = true;
        // console.log('viewComprovacaoAnexoCompr: ', this.viewComprovacaoAnexoCompr);
      }
    );

    this.comprovacaoAuxilioEducacaoService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView, true).subscribe(
      result => {
        // console.log('result: ', result);
        this.viewComprovacaoAnexoRenov = result;
        if (this.anexoDocInstituicao != null) {
          this.anexoDocInstituicao.filesToUpload = [];
        }
        if (this.anexoDocInstituicao != null) {
          this.anexosTransporte.filesToUpload = [];
        }
        if (this.anexosDeclaracaoEmpregador != null) {
          this.anexosDeclaracaoEmpregador.filesToUpload = [];
        }
        this.isReady2 = true;
        // console.log('viewComprovacaoAnexoRenov: ', this.viewComprovacaoAnexoRenov);
        // console.log('viewComprovacaoAnexoRenov: ', this.viewComprovacaoAnexoRenov.comprovacaoBenef);
        this.valorReembolso = this.viewComprovacaoAnexoRenov.comprovacaoBenef.outrosDados.renovacao.valorReembolso.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        // let valorReembolsoBR = this.valorReembolso.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
        // valorReembolsoBR = this.valorReembolso;
      }
    );
  }

  ngOnDestroy() {
    // console.log( 'ConsultaPendenciaDepComponent:ngOnDestroy' );
    /*
    setTimeout(() => {
      if (this.cd !== null &&
        this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }

    }, 250);
    */
  }

}
