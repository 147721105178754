import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { LoggedUserService, ToastService } from '@mpng/home';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Router } from '@angular/router';

import { ComprovacaoAuxilioEducacaoService } from '../shared/services/comprovacao-auxilio-educacao.service';
import { AnexarArquivoComponent } from '../anexar-arquivo/anexar-arquivo.component';
import { ListaAnexoComponent } from '../shared/lista-anexo/lista-anexo.component';


import { BenefDenpendenteComponent } from './benef-denpendente/benef-denpendente.component';


@Component({
  selector: 'app-comprovacao-usuario',
  templateUrl: './comprovacao-usuario.component.html',
  styleUrls: ['./comprovacao-usuario.component.css']
})
export class ComprovacaoUsuarioComponent implements OnInit {

  // Refactor do list-benef
  listViewBenef: any [];


  private idsBenefPendente: number [] = [];

  @ViewChildren(BenefDenpendenteComponent) existeAlteracaoAnexoDep: QueryList<BenefDenpendenteComponent>;

  @Input() comprovacaoView: any;




  private viewComprovacao: any;

  private comprovacaoProprio: any;
  private comprovacaoDependentes: any[];
  private menorStatusDependente : number = 0;
  private temProprio: boolean = false;
  private temDependente: boolean = false;
  private isReadyListBenef: boolean = false;


  // Chave de exibição do texto no fieldset
  private fieldSet: boolean;

  existeListViewBenef: boolean = false;


  // Anexar arquivo
  @ViewChild('anexoDocInstituicao', {static: false}) anexoDocInstituicao: AnexarArquivoComponent;
  @ViewChild('anexosTransporte', {static: false}) anexosTransporte: AnexarArquivoComponent;
  @ViewChild('anexosDeclaracaoEmpregador', {static: false}) anexosDeclaracaoEmpregador: AnexarArquivoComponent;
  @ViewChild('listagemAnexoInstituicao', {static: false}) listagemAnexoInstituicao: ListaAnexoComponent;
  @ViewChild('listagemAnexoTransporte', {static: false}) listagemAnexoTransporte: ListaAnexoComponent;
  @ViewChild('listagemAnexoDeclServidor', {static: false}) listagemAnexoDeclServidor: ListaAnexoComponent;


  constructor(
    private dialog: MatDialog,
    private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    private userService: LoggedUserService
  ) {
    this.testaExistePeriodo();
  }

  ngOnInit() {

    // console.log(this.userService.getLoggedUser());

    this.fieldSet = false;

  }

  buildViewlistBeneficiario() {
    this.comprovacaoAuxilioEducacaoService.viewlistBeneficiario().subscribe(
      result => {

        this.listViewBenef = result;
        // console.log('listBenef: ', this.listViewBenef);

        if (result && result.length > 0) {

          // console.log('tipoBeneficiario: ', result[0].comprovacaoBenefViewDTO.tipoBeneficiario);
          result.forEach(element => {
            if (element.comprovacaoBenefViewDTO.tipoBeneficiario === 'P') {
              this.comprovacaoProprio = element;
              this.temProprio = true;
            }  
          });
          

          if (this.temProprio) {
            // console.log('comprDep Antes: ', this.comprovacaoDependentes);
            this.comprovacaoDependentes = result.filter(element=>{
              return !(element.comprovacaoBenefViewDTO.tipoBeneficiario === 'P');
            }) ;
            // console.log('comprDep Depois: ', this.comprovacaoDependentes);
            // console.log('comprDep Size: ', this.comprovacaoDependentes.length);
            if (this.comprovacaoDependentes.length > 0) {
              this.temDependente = true;

              if (this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante === null && this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.statusComprovante === 'Documentos Não Enviados') {
                this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante = 1;
              }
            }
            // console.log('comprovacaoDependentes: ', this.comprovacaoDependentes);
            // statusComprovante
            // console.log('comprovacaoDependentes: ', this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante);

          }
          else {
            this.comprovacaoDependentes = result;
            // console.log('comprDep Depois: ', this.comprovacaoDependentes);
            // console.log('comprDep Size: ', this.comprovacaoDependentes.length);
            if (this.comprovacaoDependentes.length > 0) {
              this.temDependente = true;

              if (this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante === null && this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.statusComprovante === 'Documentos Não Enviados') {
                this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante = 1;
              }
            }
          }
        }
        // console.log('listViewBenef:', this.listViewBenef);
        // console.log('comprovacaoProprio:', this.comprovacaoProprio);
        // console.log('comprovacaoDependentes:', this.comprovacaoDependentes);
        // console.log('comprovacaoDependentes: ', this.comprovacaoDependentes[0].comprovacaoBenefViewDTO.idStatusComprovante);

        if(this.comprovacaoDependentes != null && this.comprovacaoDependentes.length > 0){
          var arr = this.comprovacaoDependentes.filter(f=>{
            var ret = false;
            if(f.comprovacaoBenefViewDTO != null && f.comprovacaoBenefViewDTO.idStatusComprovante != null){
              ret = f.comprovacaoBenefViewDTO.idStatusComprovante == '1' || f.comprovacaoBenefViewDTO.idStatusComprovante == '3'
              || f.comprovacaoBenefViewDTO.idStatusComprovante == 1 || f.comprovacaoBenefViewDTO.idStatusComprovante == 3;
            }
            return ret; });
          if(arr && arr.length > 0){
            var stAux = arr.sort( function(a, b){
              return Number(a.comprovacaoBenefViewDTO.idStatusComprovante) - Number(b.comprovacaoBenefViewDTO.idStatusComprovante);
            })[0].comprovacaoBenefViewDTO.idStatusComprovante;
            if(stAux ){
              this.menorStatusDependente = Number(stAux);
            }
          }
          
          
        }
        this.buidListPendente();
        this.isReadyListBenef = true;
      }
    );
  }

  // Mudança de estado do fieldset
  changeFieldSet() {
    if (this.fieldSet === false) {
      this.fieldSet = true;
      return this.fieldSet;
    }
  }

  testaExistePeriodo() {
    this.comprovacaoAuxilioEducacaoService.existePeriodoRefAberto().subscribe(
      result => {
        if (result === false) {
          this.messageService.add({
            key: 'tc',
            sticky: true,
            severity: 'warn',
            summary: 'ERROR',
            detail: 'Não existe período de referência aberto para comprovação de auxílio-educação.'
          });
          // Todo - Verificar no fechamento da versão se ainda há a necessidade
          // document.getElementById('divPeriodoComprovacaoAtivo').style.display = 'none';
        } else {
          this.buildViewComprovacao();
          this.buildViewlistBeneficiario();

          if ((this.getListViewBenef() && this.getListViewBenef().length) !== 0) {
            this.existeListViewBenef = true;
          }
          // console.log('existeListViewBenef: ', this.existeListViewBenef);
        }
      }
    );
  }




  // Métodos criados para a comprovação do benefício
  buildViewComprovacao() {
    this.comprovacaoAuxilioEducacaoService.viewComprovacao().subscribe(
      result => {
        this.viewComprovacao = result;
        // console.log('viewComprovacao', this.viewComprovacao);
        // console.log('statusID: ', this.viewComprovacao.statusComprovanteDTO.id);

        this.comprovacaoAuxilioEducacaoService.obtemStatusGeral().subscribe(
          resultStatusGeral => {
            this.viewComprovacao.statusComprovanteDTO = resultStatusGeral;
          });
      }
    );


  }


  desabilitarBotaoEnviar(){
    return (!(this.menorStatusDependente == 1 
    || this.menorStatusDependente == 3)  
    && this.existeListViewBenef)
  }

  enviarComprovacao(evento) {
    var botao = evento.currentTarget;
    botao.disabled = true;
    this.messageService.clear();
    if (this.existeAlteracao()) {
      this.messageService.add({
        key: 'tc',
        sticky: true,
        severity: 'warn',
        summary: 'ERROR',
        detail: 'Existem alterações não salvas. Salve ou cancele antes de enviar a declaração.'
      });
      botao.disabled = this.desabilitarBotaoEnviar();
      return;
    }

    const msg: string = ' Atenção! Após enviar a documentação, não será permitido anexar novos documentos ou modificar os documentos enviados. Deseja continuar?';
    this.confirmationService.confirm({
      key: 'dialogConfirmEnvio',
      message: msg,
      
      accept: () => {
        this.comprovacaoAuxilioEducacaoService.enviarComprovacao().subscribe(
          result => {
            // tslint:disable-next-line:no-non-null-assertion
            if (result!.errorCode !== undefined) {
              this.messageService.add({key: 'tc', sticky: true, severity: 'warn', summary: 'ERROR', detail: result.message});
              botao.disabled = this.desabilitarBotaoEnviar();;
            } else {
              var arr = result.message.split("|");
              if( arr && arr.length > 0){
                arr.forEach(element => {
                  if(element.trim() != ""){
                    var severityAux = "success";
                    var msg = "";
                    if(element.includes("severity:")){
                      severityAux = element.split("severity:")[1].trim();
                      msg = element.split("severity:")[0];
                    }else{
                      msg = element;
                    }
                    this.messageService.add({
                      key: 'tc',
                      severity: severityAux,
                      sticky: true,
                      summary: severityAux == "success"? 'SUCESSO':'ERRO',
                      detail: msg
                    });  
                  }
                });
              }else{
                this.messageService.add({
                  key: 'tc',
                  severity: 'success',
                  sticky: true,
                  summary: 'SUCESSO',
                  detail: result.message // 'Documentos enviados com sucesso. Confirmação enviada para seu e-mail funcional.'
                });
              }
              
              this.buildViewComprovacao();
              this.buildViewlistBeneficiario();
              botao.disabled = this.desabilitarBotaoEnviar();
            }
          }
        );
      },
      reject: () => {
        botao.disabled = this.desabilitarBotaoEnviar();
      }
      
    });
  }


  // Listagem dos beneficiários
  public getListViewBenef(): any [] {
    // console.log('listViewBenef: ', this.listViewBenef);
    return this.listViewBenef;
  }



  buidListPendente() {
    for (const benef of this.listViewBenef) {
      if (benef.comprovacaoBenefViewDTO.pendente === 'S') {
        this.idsBenefPendente.push(benef.comprovacaoBenefViewDTO.id);
      }
    }
  }

  public existeAlteracao(): boolean {
    const arrayAnexarComprovDep: BenefDenpendenteComponent[] = this.existeAlteracaoAnexoDep.toArray();
    for (const testaAlteracaoAnexoDep of arrayAnexarComprovDep) {
      if (testaAlteracaoAnexoDep.anexarComprovDep.existeAlteracao()) {

        return true;
      }
    }
    return false;
  }

  /*public existeAlteracao(): boolean {
    const arrayComprpovAuxComp: ComprovacaoAuxilioEducacaoAnexarComprovComponent[] = this.arrayAnexarComprovantes.toArray();
    for (const comprovAuxComp of arrayComprpovAuxComp) {
      if (comprovAuxComp.existeAlteracao()) {
        return true;
      }
    }
    return false;
  }*/

}
