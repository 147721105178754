import {OutrosDados} from './comprovacao-outros-dados';
import { ComprovacaoWeb } from './comprovacao-web.dto';


export class ComprovacaoBenf {
  id: number;
  comprovSituacaoDocumentoInstituicao: string;
  comprovSituacaoDocumentoTransp: string;
  comprovMatriculaConjugue: string;
  /*TODO CONCLUSÃO CURSO*/
  comprovSituacaoConclusaoCurso: string;
  tipoBeneficio: string;
  cdMatricula: string;

  renovSituacaoDocumentoInstituicao: string;
  renovSituacaoDocumentoTransp: string;
  renovMatriculaConjugue: string;

  qtdAnexo: number;
  numDepen: number;
  nomeDependente: string;
  totalDocAnexadoInstituicao: number;
  totalDocAnexadoTransporte: number;
  totalDocAnexadoDeclaracaoEmpregador: number;
  totalDocAnexadoConclusaoCurso: number;
  selectedIdsExclusao: number[];
  anexosDeclaracaoEmpregadorExiste: string;

  comprovMatriculaConjugueExiste: string;
  comprovDeclaracaoEmpregadorExiste: string;
  renovDeclaracaoEmpregadorExiste: string;
  comprovacaoWeb: ComprovacaoWeb;

  outrosDados: OutrosDados;

  outrosDadosJSON: string;
  isPendente?: string;

}
