export class ComprovacaoByFilterDTO {

  idPeriodoRef: number;
  anoReferencia: number;
  nomeFuncionario: string;
  matricula: string;
  statusMembro: string;
  idStatusMembro: number;
  statusDependentes: string;
  idStatusDependentes: number;
  totBeneficiarios: number;
  totDependentes: number;
  totMembros: number;
  rowNum: number;
  dataComprovacaoMembro:Date;
  dataComprovacaoDependentes: Date;
  nrProcessosMembro: string;
  nrProcessosDependentes: string;
}
