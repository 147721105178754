import { Component, OnInit, Input, ViewChild } from '@angular/core';

import { MpFileUploadComponent } from '@mpng/home';

@Component({
  selector: 'anexar-arquivo',
  templateUrl: './anexar-arquivo.component.html',
  styleUrls: ['./anexar-arquivo.component.css']
})
export class AnexarArquivoComponent implements OnInit {

  @ViewChild('fileUploadComp', {static: false}) fileUploader: MpFileUploadComponent;

  @Input() tituloAnexar: string;
  filesToUpload: any[] = [];

  anexo: any;
  displayPdf: boolean = false;
  title: string;

  constructor() {
  }

  ngOnInit() {

    // console.log('tituloAnexar: ', this.tituloAnexar);
    // console.log('filesToUpload: ', this.filesToUpload);

  }

  myUploader(arquivo) {

    for (const file of arquivo.files) {
      // console.log('File====>:' + file);
      this.filesToUpload.push(file);

    }
    arquivo.files = [];
    // console.log(this.filesToUpload);
  }

  showPdf(arquivo) {

    const reader = new FileReader();
    reader.onload = (e) => {
      this.title = arquivo.name;
      const dataURI = reader.result as string;
      const BASE64_MARKER = ';base64,';
      const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      const base64 = dataURI.substring(base64Index);
      this.anexo = base64;
      this.displayPdf = true;
    };

    reader.readAsDataURL(arquivo);
  }

  deletePDF(arquivo) {
    const index: number = this.filesToUpload.indexOf(arquivo);
    if (index !== -1) {
      this.filesToUpload.splice(index, 1);
    }
  }

}
