import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef, ViewRef, ViewChild } from '@angular/core';

import { ComprovacaoBenfView } from '../../../model/comprovacao-benefview';
import { AnexarArquivoComponent } from '../../../anexar-arquivo/anexar-arquivo.component';
import { ComprovacaoAuxilioEducacaoService } from '../../../shared/services/comprovacao-auxilio-educacao.service';
import { OutrosDados } from '../../../model';

@Component({
  selector: 'app-consulta-pendencia-prop',
  templateUrl: './consulta-pendencia-prop.component.html',
  styleUrls: ['./consulta-pendencia-prop.component.css']
})
export class ConsultaPendenciaPropComponent implements OnInit, OnDestroy {

  @Input() comprovacaoBenfView: ComprovacaoBenfView;

  private nomeCurso: string;
  private instEnsino: string;
  private dataConclusao: Date;

  dataHoje: Date = new Date();

  private viewComprovacaoAnexoCompr: any;

  private viewComprovacaoAnexoRenov: any;

  private isReady1: boolean = false;
  private isReady2: boolean = false;

  private idStatusComprovante: number;

  // @Input() corOpcaoSim: string;
  // @Input() corOpcaoNao: string;
  // @Input() corOpcaoOutro: string;

  @ViewChild('anexoDocInstituicao', {static: false}) anexoDocInstituicao: AnexarArquivoComponent;
  @ViewChild('anexosTransporte', {static: false}) anexosTransporte: AnexarArquivoComponent;
  @ViewChild('anexosDeclaracaoEmpregador', {static: false}) anexosDeclaracaoEmpregador: AnexarArquivoComponent;

  constructor(private comprovacaoAuxilioEducacaoService: ComprovacaoAuxilioEducacaoService, private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    // console.log( 'ConsultaPendenciaPropComponent:ngOnInit()', this.comprovacaoBenfView );

    this.idStatusComprovante = Number(this.comprovacaoBenfView.idStatusComprovante);

    // Teste Novo Status "comprovação fora do caem"
    // this.idStatusComprovante = 6;

    this.buildComprovacaoAnexo();
  }

  public buildComprovacaoAnexo() {
    this.isReady1 = false;
    this.isReady2 = false;
    this.comprovacaoAuxilioEducacaoService.viewComprovacaoRenovacaoAnexo(this.comprovacaoBenfView, false).subscribe(
      result => {
        // console.log('result: ', result);
        this.viewComprovacaoAnexoCompr = result;
        // console.log('viewComprovacaoAnexoCompr: ', this.viewComprovacaoAnexoCompr);
        if (this.anexoDocInstituicao != null) {
          this.anexoDocInstituicao.filesToUpload = [];
        }
        if (this.anexoDocInstituicao != null) {
          this.anexosTransporte.filesToUpload = [];
        }
        if (this.anexosDeclaracaoEmpregador != null) {
          this.anexosDeclaracaoEmpregador.filesToUpload = [];
        }
        this.isReady1 = true;
        if (result.comprovacaoBenef.outrosDadosJSON !== null) {
          const outrosDados: OutrosDados = JSON.parse(result.comprovacaoBenef.outrosDadosJSON);
          if (outrosDados.comprovacao.cursos.length > 0) {
            this.nomeCurso = outrosDados.comprovacao.cursos[0].nome;
            this.instEnsino = outrosDados.comprovacao.cursos[0].instituicaoEnsino;
            this.dataConclusao = new Date(outrosDados.comprovacao.cursos[0].dataConclusao);
          }

        }
        // console.log('dataHoje: ', this.dataHoje);
        // console.log('dataConclusao: ', this.dataConclusao);
        // console.log( 'viewComprovacaoAnexoCompr: ', this.viewComprovacaoAnexoCompr );
      }
    );

  }

  ngOnDestroy() {
    // console.log('ConsultaPendenciaPropComponent:ngOnDestroy');
    /*
    setTimeout(() => {
      if (this.cd !== null &&
        this.cd !== undefined &&
        !(this.cd as ViewRef).destroyed) {
        this.cd.detectChanges();
      }

    }, 250);
    */
  }

}
